import React from 'react';
import './IconColumn.scss';

const IconColumn = (props) => {
    return (
        <div className="IconColumn">
            <span className={"icon icon-" + props.icon}></span>
            <div>
                {props.children}
            </div>
        </div>
    )
}

export default IconColumn