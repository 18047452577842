import React, { useEffect, useContext } from 'react';
import utils from '../../../data/utils.json';
import './Form.scss';
import axios from 'axios';
import validator from 'email-validator';
import ReCAPTCHA from "react-google-recaptcha";
import UIContext from '../../../Context/UIContext';

const Form = (props) => {
    const fields = props.data;
    const fieldNames = Object.keys(fields);
    const UICx = useContext(UIContext);
    const formBusiness = props.formBusiness;

    // Build form state
    const stateValues = fieldNames.reduce((a, b) => (a[b] = '', a), {});
    const [captcha, setCaptcha] = React.useState(false);
    const [values, setValues] = React.useState({ ...stateValues });

    // UI State
    const [loading, setLoading] = React.useState(false);
    const [formValid, setFormValid] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [sended, setSended] = React.useState(false);
    const [file, setFile] = React.useState(null);

    let captchaRef;

    const setCaptchaRef = (ref) => {
        if (ref) {
            return captchaRef = ref;
        }
    };

    const handleChange = name => (event, newValue) => {
        if (name === 'attachment') {
            setFile(event.target.files[0]);
        } else if (name === 'terms') {
            const nextVal = (newValue === undefined) ? event.target.checked : newValue;
            setValues({ ...values, [name]: nextVal });
        } else {
            const nextVal = (newValue === undefined) ? event.target.value : newValue;
            setValues({ ...values, [name]: nextVal });
        }

        validateForm();
    };

    const sendForm = (values) => {
        const url = utils.server + 'send/' + props.targetUrl;
        const formData = new FormData();
        Object.keys(values).map(key => {
            return (key !== 'attachment') ? formData.append(key, values[key]) : null;
        });

        if (file) {
            formData.append('attachment', file);
        }

        // Add recaptcha
        formData.append('recaptcha', captcha);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        return axios.post(url, formData, config)
    }

    const validateForm = () => {
        let valid = true;

        if (!(validator.validate(values.email) && values.email.length > 2)) valid = false;
        if (!captcha) valid = false;

        Object.keys(fields).map(key => {
            if (fields[key]['required']) {
                if (fields[key]['type'] != 'checkbox') {
                    return (values[key].length > 0) ? null : valid = false
                } else {
                    return (values[key]) ? null : valid = false
                }
            } else {
                return null;
            }
        });

        // File validation
        if (fields.attachment) {
            if (file) {
                if (file.size > 200000)
                    valid = false;
            } else {
                valid = false;
            }
        }

        setFormValid(valid);
    }

    const recaptchaChange = (value) => {
        setCaptcha(value);
    }

    const onFormSubmit = (e) => {
        validateForm();
        e.preventDefault();
        setShowError(false);

        if (formValid) {
            setLoading(true);
            sendForm(values).then((response) => {

                if (response.data.success) {
                    setSended(true);
                    setLoading(false);
                } else {
                    captchaRef.reset();
                    setErrorMessage(response.data.message);

                    setLoading(false);
                    setShowError(true);
                }

            })
        } else {
            setShowError(true);
        }
    }

    React.useEffect(() => {
        validateForm();
    });

    useEffect(() => {
        if (fieldNames.includes('instalation')) {
            setValues({ ...values, ['instalation']: UICx.formSubject });
        }
    }, [])

    const renderFields = fieldNames.map((name, index) => {
        switch (fields[name].type) {
            case 'checkbox':
                return <div key={"formField-" + index} className="form-check full-width text-center pt-2 pb-3">
                    <input
                        id={"check-formField-" + index}
                        className="form-check-input"
                        value={values[name]}
                        name={name} id={"form_" + name} type={fields[name].type}
                        onChange={handleChange(name)} />
                    <label className='form-check-label' htmlFor={"check-formField-" + index}>{fields[name].label}</label>
                    {(fields[name].hint && <p className="hint">{fields[name].hint}</p>)}
                </div>
            case 'textarea':
                return <div key={"formField-" + index} className="form-field textarea">
                    <textarea
                        placeholder={fields[name].label + ((fields[name].required) ? ' *' : '')}
                        className="form-control"
                        value={values[name]} cols="30" rows="6"
                        name={name} id={"form_" + name} type={fields[name].type}
                        onChange={handleChange(name)} ></textarea>
                </div>
            case 'file':
                return <div key={"formField-" + index} className="form-field">
                    <input
                        placeholder={fields[name].label + ((fields[name].required) ? ' *' : '')}
                        className="form-control form-control-file"
                        value={values[name]}
                        name={name} id={"form_" + name} type={fields[name].type}
                        onChange={handleChange(name)} />
                    {(fields[name].hint && <p className="hint">{fields[name].hint}</p>)}
                </div>
            default:
                return <div key={"formField-" + index} className={"form-field " + (fields[name].full && 'full-width')}>
                    <input
                        placeholder={fields[name].label + ((fields[name].required) ? ' *' : '')}
                        className="form-control"
                        value={values[name]}
                        name={name} id={"form_" + name} type={fields[name].type}
                        onChange={handleChange(name)} />
                    {(fields[name].hint && <p className="hint">{fields[name].hint}</p>)}
                </div>
        }
    });

    return !formBusiness ? (
        <div className="Form">
            <form onSubmit={onFormSubmit} className="styled-form" name={"contact-form"}>
                {renderFields}
                {showError && <div className="form__error alert alert-warning">{props.error}</div>}
                <div className="form__recaptcha">
                    <ReCAPTCHA
                        ref={(r) => setCaptchaRef(r)}
                        sitekey="6Lf_j_oUAAAAAI_mf9OyKa5dRRoeg5pib5MrUEfI"
                        onChange={recaptchaChange}
                    />
                </div>
                <div className="form__actions">
                    {(sended)
                        ? <div className="form__success">{props.sended}</div>
                        : (!loading)
                            ? <>
                                <button id={props.formId ? props.formId : 'contacto'} type="submit" className={"submit btn " + ((formValid) ? ' btn-primary' : ' btn-outline')}>
                                    {props.send}
                                </button>
                                <div className="required-notice">{props.footer}</div>
                            </>
                            : <div className="flex-center btn-outline submit">
                                <strong>Enviando </strong>
                                <div className="icon-secondary d-inline-block now-loading icon-spinner"></div>
                            </div>

                    }
                </div>
            </form>
        </div>
    ) : (
        <div className="Form">
            <form onSubmit={onFormSubmit} className="styled-form" name={"contact-form"}>
                {renderFields}
                {showError && <div className="form__error alert alert-warning">{(errorMessage) ? errorMessage : props.error}</div>}
                <div className="form__recaptcha">
                    <ReCAPTCHA
                        ref={(r) => setCaptchaRef(r)}
                        sitekey="6Lf_j_oUAAAAAI_mf9OyKa5dRRoeg5pib5MrUEfI"
                        onChange={recaptchaChange}
                    />
                </div>
                <div className="form__actions">
                    {(sended)
                        ? <div className="form__success">{props.sended}</div>
                        : (!loading)
                            ? <>
                                <button id={props.formId ? props.formId : 'contacto'} type="submit" className={"submit btn btn-primary"}>
                                    {props.send}
                                </button>
                                <div className="required-notice">{props.footer}</div>
                            </>
                            : <div className="flex-center btn-outline submit">
                                <strong>Enviando </strong>
                                <div className="icon-secondary d-inline-block now-loading icon-spinner"></div>
                            </div>

                    }
                </div>
            </form>
        </div>
    )
}

export default Form