import React, { useState, useEffect } from 'react';
import utils from '../../../data/utils.json';
import './History.scss';
import axios from 'axios';
import ReactHtmlParser from 'react-html-parser';

const History = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(utils.server + 'get/history')
            .then(function (response) {
                setData(response.data);
            })
    }, []);

    let colorIndex = [];
    let lastColor = '000';

    const renderHistory = (data !== null) ?
        data.map(
            item => {

                if (item.color && item.color !== lastColor) {
                    lastColor = item.color;
                    colorIndex[item.year] = lastColor;
                }

                return (
                    <div className="history__item" key={'history-' + item.id} id={"y" + item.year}>
                        <div className="history__card">
                            <div className="history__image">
                                {item.image && <img className="img-fluid" src={utils.server + 'get/image/medium/projects/' + item.image} alt={item.title} />}
                            </div>
                            <div className="history__info">
                                <h3 className="history__title">{item.title}</h3>
                                <div className="history__description">
                                    {ReactHtmlParser(item.body)}
                                </div>
                            </div>
                        </div>
                        <div className="history__timeline">
                            <div className="history__year" style={{ backgroundColor: '#' + lastColor }}>{item.year}</div>
                            <div className="history__line" style={{ borderColor: '#' + lastColor }}></div>
                        </div>
                    </div>
                )
            }
        ) : null;

    const renderIndex = (data) ?
        colorIndex.map(
            (color, year) => <a key={"index-" + year} href={"#y" + year} className="history-index__year" style={{ backgroundColor: '#' + color }}>{year}</a>
        )
        : null;

    const today = (colorIndex.length) ? <div className="history__item">
        <div className="history__timeline">
            <div className="history__year" style={{ backgroundColor: '#' + lastColor }}>HOY</div>
        </div>
    </div> : null;

    return (
        <div className="History">
            <div className="history-index">
                {renderIndex}
            </div>
            {renderHistory}
            {today}
        </div>
    )
}

export default History