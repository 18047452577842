import React from 'react'
import IconColumn from '../../UI/IconColumn/IconColumn';
import Iframe from 'react-iframe'

const Sidebar = () => {
    return (
        <>
            <IconColumn icon="phone">
                <p className="title">4775-1122</p>
            </IconColumn>
            <IconColumn icon="mail">
                <a href="mailto:hola@labadie.com.ar">hola@labadie.com.ar</a>
            </IconColumn>
            <IconColumn icon="map">
                <p>Zapata 277 (C1426AEC)<br />Buenos Aires, C.A.B.A</p>
            </IconColumn>
            <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.195876019037!2d-58.442455684278976!3d-34.57390998046723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5c00621522d%3A0x74734231615fb1bf!2sLabadie%20Videosistemas!5e0!3m2!1ses-419!2sar!4v1590088288464!5m2!1ses-419!2sar"
                width="100%"
                height="200px"
                id="gMapsId"
                className="map"
                position="relative" />
            <IconColumn icon="clock">
                <p>De lunes a viernes<br />
                    <strong>8:30 a 16.00hs</strong></p>
            </IconColumn>
        </>
    )
}

export default Sidebar