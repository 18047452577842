import React from 'react';
import { NavLink } from 'react-router-dom';
import utils from '../../../../data/utils.json';

const CategoryItem = (props) => {
    const data = props.data;

    const renderData = (data) ?
        <NavLink to={"/productos/" + data.slug + "/1"} className={"CategoryItem " + (props.active && 'active')}>
            <span className="ci__area">Productos</span>
            <span className="ci__title">{data.name}</span>
            <span className="ci__hover"><span className="icon icon-plus"></span></span>
            <img className="ci__bg" src={utils.server + "get/image/medium/thumbnails/" + data.featuredImage} alt={data.title} />
        </NavLink> : <NavLink to={"/productos/todos/1"} className={"CategoryItem " + (props.active && 'active')} >
            <span className="ci__title">TODOS LOS <strong>PRODUCTOS</strong></span>
        </NavLink>;


    return renderData;
}

export default CategoryItem