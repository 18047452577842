import React from 'react';
import imgIntro from '../../../../assets/images/almacenes-intro.png';
import imgList from '../../../../assets/images/almacenes-list.png';
import HeroContainer from '../../../Layout/HeroContainer/HeroContainer';
import InfoAccordeon from '../../../UI/InfoAccordeon/InfoAccordeon';
import TitleMedium from '../../../UI/TitleMedium/TitleMedium';
import Iframe from 'react-iframe';

import './BusinessInner.scss';

const BusinessInner = ({ children, hero, info, beneficios, slug, maps = null, mapAction }) => {

    const scrollToElem = (elem) => {
        document.getElementById(elem).scrollIntoView({
            behavior: 'smooth'
        });
    }

    return (
        <div className={"BusinessInner theme-" + slug}>
            <HeroContainer
                section={hero.section}
                title={hero.title}
                subtitle={hero.subtitle}
                action={scrollToElem}
                slug={slug}
            />
            <div className="IntroContainer container-center">
                <div className="image">
                    <img className='img-fluid' src={imgIntro} alt="Teléfono Universal Veo F2 - Labadie by Fermax" />
                </div>
                <div className="info">
                    {info.body || <>
                        <p>Sabemos que tu negocio requiere de brindarle a tus clientes productos nuevos de manera constante pero que a su vez sean confiables y de buena calidad.</p>
                        <p>Por eso, te presentamos el <strong>Teléfono Universal Veo F2</strong> de <strong>Labadie by Fermax</strong>, un nuevo concepto de teléfono, para que puedas sumarlo a tu lista de productos. Presenta un diseño integrado, acorde a las nuevas tendencias y estilos de vida, sencillo y funcional que aúna calidad y diseño. Puede sustituir a la mayoría de los teléfonos estándar de otras marcas por reemplazo directo para instalaciones convencionales de <strong>4+N</strong> y <strong>5+N</strong>. Permite la comunicación con la placa de la calle, así como la apertura de la puerta de acceso. </p>
                    </>}
                </div>
            </div>
            <div className="ListContainer container-center" id='feature-list'>
                <div className="inner">
                    <div className="image">
                        <img src={imgList} className='img-fluid' alt="Teléfono Universal Veo F2 - Labadie by Fermax" />
                    </div>
                    <div className="info">
                        <TitleMedium section={info.section}>{beneficios.title}</TitleMedium>
                        <div className="ListAccordeon mt-4">
                            {beneficios.accordion.map((item, index) => <InfoAccordeon theme={slug} key={'beneficios-accordion-' + index} accordionId={index + 1} name={item.title}>
                                {item.body}
                            </InfoAccordeon>)}
                        </div>
                    </div>
                </div>
            </div>
            {maps && <div className="MapContainer">
                <div className='section-container'>
                    <div className="inner">
                        {maps.map((item, index) => <div key={'landing-map-' + index} className="info__images info__map">
                            <h4 className='title'>{item.title}</h4>
                            <Iframe url={item.map}
                                width="100%"
                                height="400px"
                                id="gMapsId"
                                className="map"
                                position="relative" />
                        </div>)}
                    </div>
                </div>
                {mapAction && <div className='text-center mt-3 mb-2'><a className='text-primary btn btn-light' onClick={mapAction}>Condiciones Instaladores Sugeridos </a></div>}
            </div>}
            {/* Form Format / ErrorHandlers */}
            <div className="ActionContainer" id='action-form'>
                <div className="section-container">
                    <i></i>
                    <h5 className="section">{info.section}</h5>
                    <i></i>
                </div>
                <h1 className='title'>SUMATE GRÁTIS AHORA</h1>
                <div className="container-center">
                    <div className="FormContainer">
                        <h3 className="sub-title">Registrate y accedé a todos los beneficios</h3>
                        {children}
                    </div>
                </div>
            </div>
            {/* Footer */}
        </div>
    )
}

export default BusinessInner