import React from 'react';
import iconAlmacenes from '../../../assets/images/areas-de-negocio-1.png';
import iconGremio from '../../../assets/images/areas-de-negocio-2.png';
import iconConsumidor from '../../../assets/images/areas-de-negocio-3.png';
import NavItem from '../../UI/NavItem/NavItem';
import { NavLink } from 'react-router-dom';
import './AreasDeNegocio.scss';


const AreaItem = ({ title, icon, target, body }) => {
    return (
        <div className="AreaItem">
            <div className='ImageContainer'>
                {target
                    ? <NavLink to={target}><img src={icon} alt="" /></NavLink>
                    : <img src={icon} alt="" />}
            </div>
            <h3>{title}</h3>
            <div className='AreaItemBody'>{body}</div>
            {target ? <NavItem target={target}>VER MÁS</NavItem> : <div className="NavItem disabled">Próximamente</div>}
        </div>
    )
}

const AreasDeNegocio = () => {

    return (
        <div className="AreasDeNegocio">
            <AreaItem title='ALMACENES' icon={iconAlmacenes} target={'/areas/almacenes'} body={
                <p>¿Sabías que si te adheris a nuestra Red de Almacenes podes disfrutar de descuentos especiales mientras amplias tu gama de productos, capacitaciones gratuitas y aparecer en nuestro mapa interactivo?  Hacé click acá y enterate de nuestras novedades antes que otros y asegurate que tus clientes estén siempre a la vanguardia.</p>
            } />
            <AreaItem title='GREMIO' icon={iconGremio} target={'/areas/gremio'} body={
                <p>¿Sabías que podes recibir nuestras capacitaciones gratuitas y descuentos especiales, estar siempre al tanto de nuestras novedades y ofrecerle a tus clientes lo último en tecnología? Adherite a nuestra Red de Gremios y empezá a ampliar tu clientela.</p>
            } />
            <AreaItem title='CONSUMIDOR FINAL' target={'/areas/consumidor-final'} icon={iconConsumidor} body={
                <p> ¿Sabías que nuestros productos de origen español se caracterizan por estar siempre a la vanguardia de la tecnología? ¿Y sabías que contamos con una Red de Almacenes donde podes conseguirlos y un listado de Gremios capacitados que pueden ayudarte a elegir lo que estás buscando e instalarlo? ¡Anotate acá y recibí nuestro newsletter con las últimas novedades para estar siempre a la vanguardia!</p>
            } />
        </div>
    )
}

export default AreasDeNegocio