import React, { useState, useContext, useEffect } from 'react';
import './InfoAccordeon.scss';
import { useMeasure } from 'react-use';
import { animated, useSpring } from 'react-spring';
import UIContext from '../../../Context/UIContext';

const InfoAccordeon = (props) => {
    const [shown, setShown] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    const UICx = useContext(UIContext);

    // Measures
    const [wrapperRef, { height }] = useMeasure();

    //Animation styles
    const showSpring = useSpring({
        height: shown ? contentHeight + 'px' : '0px',
        config: { frequency: 1 }
    });

    const arrowSpring = useSpring({
        transform: shown ? 'rotate(180deg)' : 'rotate(0deg)',
        // color: shown ? '#00FFFF' : "#7E1F7E"
    });

    let togglerColor;
    switch (props.theme) {
        case 'almacenes':
            togglerColor = "228,244,246";
            break;
        case 'consorcios':
            togglerColor = "230,240,235";
            break;
        default:
            togglerColor = "220,229,235";
    }

    const wrapperSpring = useSpring({
        backgroundColor: shown ? 'rgba(' + togglerColor + ',0)' : 'rgba(' + togglerColor + ',1)',
        // color: shown ? '#FFF' : "#333"
    });

    // Methods

    const toggleCollapse = () => {
        // setShown(!shown);
        UICx.setInfoAccordion(
            (props.accordionId == UICx.currentInfoAccordion) ? null : props.accordionId
        );
    }

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
        // if (UICx.currentInfoAccordion !== props.accordionId) setShown(false);
        setShown(UICx.currentInfoAccordion == props.accordionId);
    }, [UICx.showDrawer, UICx.currentInfoAccordion, height, props.accordionId]);

    return (
        <animated.div className="InfoAccordeon" style={wrapperSpring}>
            <div className="toggler" onClick={toggleCollapse}>
                {props.name}
                <animated.span style={arrowSpring} className="icon-cheveron-down icon"></animated.span>
            </div>
            <animated.div className="collapse-wrapper" style={showSpring} >
                <div className="inner" ref={wrapperRef}>
                    {props.children}
                </div>
            </animated.div>
        </animated.div>
    )
}

export default InfoAccordeon