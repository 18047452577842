import React, { useState, useContext, useEffect } from 'react';
import './BtnCollapse.scss';
import { useMeasure } from 'react-use';
import { animated, useSpring } from 'react-spring';
import UIContext from '../../../Context/UIContext';

const BtnCollapse = (props) => {
    const [shown, setShown] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);

    const UICx = useContext(UIContext);

    // Measures
    const [wrapperRef, { height }] = useMeasure();

    //Animation styles
    const showSpring = useSpring({
        height: shown ? contentHeight + 'px' : '0px'
    });

    const arrowSpring = useSpring({
        transform: shown ? 'rotate(180deg)' : 'rotate(0deg)',
        // color: shown ? '#00FFFF' : "#7E1F7E"
    });

    const wrapperSpring = useSpring({
        backgroundColor: shown ? 'rgba(255,255,255,0.5)' : 'rgba(255,255,255,0)',
        // color: shown ? '#FFF' : "#333"
    });

    // Methods

    const toggleCollapse = () => {
        setShown(!shown);
        UICx.setAccordion(props.name);
    }

    useEffect(() => {
        //Sets initial height
        setContentHeight(height);
        if (!UICx.showDrawer || UICx.currentAccordion !== props.name) setShown(false);
    }, [UICx.showDrawer, UICx.currentAccordion, height, props.name]);

    return (
        <animated.div className="BtnCollapse" style={wrapperSpring}>
            <div className="toggler" onClick={toggleCollapse}>
                {props.name}
                <animated.span style={arrowSpring} className="icon-cheveron-down icon"></animated.span>
            </div>
            <animated.div className="collapse-wrapper" style={showSpring} >
                <div className="inner" ref={wrapperRef}>
                    {props.children}
                </div>
            </animated.div>
        </animated.div>
    )
}

export default BtnCollapse