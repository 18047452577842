import React, { useEffect, useState, useContext } from 'react';
import './ProductList.scss';
import axios from 'axios';
import utils from '../../../data/utils.json';
import Pagination from 'react-js-pagination';
import { withRouter, useHistory } from 'react-router-dom';
import Slider from "react-slick";
import WithTitle from '../../HOC/WithTitle/WithTitle';
import ProductItem from './ProductItem';
import ProductCategoriesFull from '../ProductCategories/ProductCategoriesFull';
import PreLoader from '../../UI/PreLoader/PreLoader';
import UIContext from '../../../Context/UIContext';
import ReactHtmlParser from 'react-html-parser';

const ProductList = ({ match, ...props }) => {

    const { slug: category } = match.params;
    const { page: currentPage } = match.params;
    const UICx = useContext(UIContext);

    const [products, setProducts] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);

    const history = useHistory();

    React.useEffect(() => {
        axios.get(utils.server + 'find/products/' + category + '/' + currentPage)
            .then(function (response) {
                setProducts(response.data[1].data);
                setTotalItems(response.data[1].total * UICx.itemsPerPage);
                setLoading(false);

                if (response.data[0]) {
                    setCategoryData(response.data[0]);
                }
            })
    }, []);

    const renderProducts = (products !== null) ?
        products.map(
            item => <ProductItem key={item.id} data={item} />
        ) : <div className="no-results">
            <p>No se encontraron productos en esta categoría.</p>
        </div>;

    const renderBody = (loading) ? <PreLoader /> : <div className="list-inner">{renderProducts}</div>;

    const categoryTitle = (categoryData) ? categoryData.name : 'Todos los productos';
    const categoryDescription = (categoryData) ? ReactHtmlParser(categoryData.body) : null;

    let categorySlide = null;
    if (categoryData) {

        // Slide
        const settings = {
            dots: true,
            infinite: true,
            speed: 800,
            autoplaySpeed: 4000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            arrows: false,
            // cssEase: 'cubic-bezier(0.665, 0.005, 0.330, 1)',
            // customPaging: function (i) {
            //     return (
            //         <a>{i + 1}</a>
            //     );
            // }
        }

        const renderSlides = (categoryData.categoryPhoto.length) ? categoryData.categoryPhoto.map((item) => {
            const slideImage = 'get/image/slide/categories/' + categoryData.id + '/' + item.file;
            return (
                <div
                    className="slide"
                    key={"featuredSlide-" + item.id}>
                    <img className="slide__bg" src={utils.server + slideImage} alt={item.title} />
                </div>
            )
        }) : null;

        categorySlide = (categoryData.categoryPhoto.length) ? <Slider
            {...settings}
            className="FeaturedSlider">
            {renderSlides}
        </Slider> : null;
    }


    return (
        <div className="ProductList">
            {categorySlide}
            <WithTitle
                section='PRODUCTOS'
                title={categoryTitle}
                description={categoryDescription}>
                {renderBody}
                {totalItems > UICx.itemsPerPage && <Pagination
                    activePage={parseInt(currentPage)}
                    itemsCountPerPage={UICx.itemsPerPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    hideNavigation={true}
                    hideFirstLastPages={true}
                    innerClass={"Pagination"}
                    onChange={(n) => history.push('/productos/' + category + '/' + n)}
                />}
            </WithTitle>
            <div className="main-block">
                <ProductCategoriesFull current={category} />
            </div>
        </div>
    )
}

export default withRouter(ProductList)