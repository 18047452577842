import React, { useContext } from 'react';
import './NavItem.scss';
import { NavLink } from 'react-router-dom';
import UIContext from '../../../Context/UIContext';

const NavItem = (props) => {
    let tg = props?.target??null;
    let st = props?.section??null;
    let tagMailSender = (st?st:'') + (tg?tg.replaceAll("/",'-'):'');
    const UICx = useContext(UIContext);
    const link = (props.target) ?
        
        <NavLink className="NavItem" onClick={(UICx.showDrawer) ? UICx.drawerTogglerHandler : null} 
        to={ { pathname: props.target, state: { tagMailSender: tagMailSender!==''?tagMailSender:null} } }>{props.children}</NavLink> :
        <button className="NavItem">{props.children}</button>;

    return link;
}

export default NavItem