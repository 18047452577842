import React from 'react'
import TextIcon from '../../UI/TextIcon/TextIcon';
import UIContext from '../../../Context/UIContext';

const NavData = () => {
    const UICx = React.useContext(UIContext);
    return (
        <div className="NavData">
            <TextIcon clicked={() => UICx.setShowModal('map')} icon="map" mode="light">Zapata 277, Buenos Aires, CABA</TextIcon>
            <TextIcon icon="phone" mode="light">4775-1122</TextIcon>
        </div>
    )
}

export default NavData