import React from 'react';
import './Terms.scss';

const Terms = () => {
    return (
        <div className="Terms">
            <p>LABADIE incluye en su lista de Electricistas y Técnicos SUGERIDOS a todos aquellos que han participado de las capacitaciones provistas por LABADIE by FERMAX y no se hará responsable ni asumirá garantía alguna por el trabajo de instalación/mano de obra del Electricista y/o Técnico SUGERIDO. En consecuencia, el CONSUMIDOR FINAL acuerda, mediante la aceptación de estos términos, eximir de responsabilidad a LABADIE frente a cualquier reclamo o demanda como consecuencia de equipos mal instalados o fallas en la instalación. LABADIE ofrece al CONSUMIDOR FINAL la posibilidad de dejar asentados sus datos a fin de realizar un seguimiento y verificar el trabajo del Electricista/Técnico SUGERIDO y la conformidad del CONSUMIDOR FINAL.</p>
        </div>
    )
}

export default Terms