import React from 'react';
import SocialNavItem from './SocialNavItem/SocialNavItem';
// import utils from '../../../data/utils.json';

const SocialNav = () => {
    return (
        <nav className="SocialNav">
            <SocialNavItem icon="instagram" to="https://www.instagram.com/labadiebyfermax/" />
            <SocialNavItem icon="linkedin" to="https://www.linkedin.com/in/labadie-by-fermax/" />
            <SocialNavItem icon="whatsapp" to="https://wa.me/5491151814378" />
            <SocialNavItem icon="youtube" to="https://www.youtube.com/channel/UCsTajG60qfF5oSoLbP_1SKg" />
        </nav>
    );
};

export default SocialNav;
