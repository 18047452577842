import React, { useState, useContext, useEffect, useRef } from 'react';
import './Projects.scss';
import SectionInfo from '../../HOC/SectionInfo/SectionInfo';
import { withRouter, useHistory } from 'react-router-dom';
import ProjectItem from './ProjectItem';
import UIContext from '../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Pagination from 'react-js-pagination';
import Slider from "react-slick";
import SliderArrows from '../../UI/SliderArrows/SliderArrows';

const ProjectDetail = ({ match, ...props }) => {
    const slider = useRef(null);
    const [data, setData] = useState(null);
    const [countSlide, setcountSlide] = useState(null);
    const { slug: currentSlug } = match.params;
    const [loading, setLoading] = useState(true);
    

    useEffect(() => {
        axios.get(utils.server + 'find/project/' + currentSlug)
            .then(function (response) {
                setcountSlide(response.data?.projectPhoto.length??0 );
                setData(response.data);
                setLoading(false);
            })
    }, []);

    const settings = {
        infinite: true,
        slidesToShow: countSlide>2?3:countSlide,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: true,
        arrows: false,
        mobileFirst: true,
          responsive: [
                {
                  breakpoint: 576,
                  settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                    pauseOnHover: true,
                    arrows: false,
                    mobileFirst: true,
                  }
                }
              ]
        
    }
    const gallery = (data) ? data.projectPhoto.map(item => <div key={"gallery-" + item.id} className="gallery-item">
        <img className="img-fluid" src={utils.server + "get/image/slide_med/projects/" + data.id + "/" + item.file} alt={data.title} />
    </div>) : '';
    const estilo = {
        textAlign : "center",
        marginBottom: "25px"
    }
    const estilo2 = { marginTop: "10px"}

    const projects = (data)?
        <>
            <div className="ProjectItem">
                <div className="row">
                    <h3 className="projectItem__title col-12 col-sm-12 col-md-12" style={estilo} >{data.title}</h3>
                    <div className="projectItem__image col-12 col-sm-6 col-md-6">
                        <img className="img-fluid" src={utils.server + "get/image/gallery_full/projects/" + data.id + "/" + data.featuredImage} alt={data.title} />
                    </div>
                    <div className="projectItem__body col-12 col-sm-6 col-md-6">{ReactHtmlParser(data.body)}</div>
                </div>
                <div className="" style={estilo2}>
                    <SliderArrows slider={slider} items={data.projectPhoto}>
                        <Slider ref={slider} {...settings}>
                            {gallery}
                        </Slider>
                    </SliderArrows>
                </div>
            </div>
        </>: '';
    const goBack = true;
    const renderBody = (!loading) ?
    <>
        <WithTitle
            section='EMPRESA'
            title={'PROYECTOS DESTACADOS'} goBack={goBack}/>
        <div className="projects-wrapper main-block--padding">
            {projects}
        </div>
    </> : <PreLoader />;


    return (
        <div>
            {renderBody}
            
        </div>
    )
}

export default withRouter(ProjectDetail);