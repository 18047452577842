import React, { useState, useContext, useEffect } from 'react';
import './Info.scss';
import { withRouter } from 'react-router-dom';
import UIContext from '../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';

const Info = (props) => {
    const uiCx = useContext(UIContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchUrl = "get/info/51";
        axios.get(utils.server + fetchUrl)
            .then(function (response) {
                setData(response.data);
            })
    }, []);

    const gallery = (data) ? data.postPhoto.map(item => <div key={"gallery-" + item.id}>
        <img className="img-fluid" src={utils.server + "get/image/medium/submissions/" + data.id + "/" + item.file} alt={data.title} />
    </div>) : null;

    const sectionRender = (data) ?
        <>
            <div className="SectionInfo">
                <WithTitle section={"EMPRESA"} title={data.title} />
                <div className="info__inner main-block--padding">
                    <div className="info__main">
                        <div className="body">
                            {ReactHtmlParser(data.body)}
                        </div>
                    </div>
                    <div className="info__images">
                        {gallery}
                    </div>
                </div>
            </div>
        </> : <PreLoader />;

    return (
        <div className="Info Clientes">
            {sectionRender}
        </div>
    )
}

export default withRouter(Info);