import React, { useState, useContext, useEffect } from 'react';
import './Contact.scss';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Form from '../../UI/Form/Form';
import Sidebar from './Sidebar';


const Capacitacion = ({ match, ...props }) => {
    //const { slug: indexSlug } = match.params;
    const [data, setData] = useState(null);

    React.useEffect(() => {
        setData(true);
    });

    const formFields = {
        name: {
            "label": "Nombre y Apellido",
            "type": "text",
            "required": true,
        },
        email: {
            "label": "Dirección de e-Mail",
            "type": "email",
            "required": true,
        },
        phone: {
            "label": "Número de teléfono",
            "type": "text",
            "required": false,
        },
        company: {
            "label": "Empresa",
            "type": "text",
            "required": true,
        },
        cargo: {
            "label": "Cargo",
            "type": "text",
            "required": true,
        },
    }

    const send = <>Enviar formulario <span className="icon icon-cheveron-right icon-secondary"></span></>;
    const sended = <>
        <div className="sended alert alert-success">Mensaje enviado, le responderemos a la brevedad. Muchas gracias. <span className="icon-checkmark icon text-success"></span></div>
    </>;
    const error = <>
        Por favor revise los campos marcados con <strong>*</strong>, y tilde la casilla de verificación.
        <span className="icon-close icon-secondary text-white"></span>
    </>;

    const footer = <p>Los campos marcados con * son obligatorios</p>;

    // Form State
    const sectionRender = (data) ?
        <Form data={formFields} sended={sended} error={error} send={send} footer={footer} targetUrl={'capacitaciones'} /> :
        <PreLoader />;

    return (
        <div className="Contact">
            <WithTitle section='CONTACTO' title={'Inscripción Capacitaciones'} type="main-block--padding">
                <div className="contact__inner">
                    <div className="contact__main">
                        {sectionRender}
                    </div>
                    <div className="contact__sidebar">
                        <Sidebar />
                    </div>
                </div>
            </WithTitle>
        </div>
    )
}

export default Capacitacion