import React, { useState, useCallback, useContext } from 'react';
import './App.scss';
import { UIProvider } from './Context/UIContext';
import { CatalogProvider } from './Context/CatalogContext';
import WindowDimensionsProvider from './Components/Utils/WindowDimensionsProvider';
// Utils
import { Switch, Route, __RouterContext } from 'react-router-dom';
import { useTransition, animated } from 'react-spring';
import axios from 'axios';
import utils from './data/utils.json';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Components
import Layout from './Components/Layout/Layout';
import Map from './Components/Pages/Map/Map';
import TermsAlmacenes from './Components/Pages/Terms/TermsAlmacenes';
import TermsGremio from './Components/Pages/Terms/TermsGremio';
import TermsConsumidor from './Components/Pages/Terms/TermsConsumidor';
import Search from './Components/Pages/Search/Search';
import Projects from './Components/Pages/Projects/Projects';
import ProjectDetail from './Components/Pages/Projects/ProjectDetail';
import Modal from './Components/UI/Modal/Modal';
import Info from './Components/Pages/Info/Info';
import Clientes from './Components/Pages/Info/Clientes';
import News from './Components/Pages/News/News';
import NewsPage from './Components/Pages/NewsPage/NewsPage';
import Contact from './Components/Pages/Contact/Contact';
import Work from './Components/Pages/Contact/Work';
import Presupuesto from './Components/Pages/Contact/Presupuesto';
import Capacitacion from './Components/Pages/Contact/Capacitacion';
import ServicioTecnico from './Components/Pages/Contact/ServicioTecnico';
import ProductList from './Components/Pages/ProductList/ProductList';
import Product from './Components/Pages/Product/Product';
import SearchResult from './Components/Pages/SearchResult/SearchResult';
import Home from './Components/Pages/Home/Home';
import Footer from './Components/Layout/Footer/Footer';
import Distribuidores from './Components/Pages/Info/Distribuidores';

import BusinessAlmacenes from './Components/Pages/BusinessAreas/BusinessAlmacenes/BusinessAlmacenes';
import BusinessConsorcios from './Components/Pages/BusinessAreas/BusinessAlmacenes/BusinessConsorcios';
import BusinessConsumidor from './Components/Pages/BusinessAreas/BusinessAlmacenes/BusinessConsumidor';
import AreasDeNegocio from './Components/Pages/AreasDeNegocio/AreasDeNegocio';

// import utils from './data/utils';
// import axios from "axios";

function App() {
    // States
    const itemsPerPage = 10;
    const [showDrawer, setShowDrawer] = useState(false);
    const [showModal, setShowModal] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [currentAccordion, setAccordion] = useState(null);
    const [currentInfoAccordion, setInfoAccordion] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [formSubject, setFormSubject] = React.useState('');

    const [featured, setFeatured] = useState(null);
    const [services, setServices] = useState(null);
    const [homeText, setHomeText] = useState(null);
    const [categories, setCategories] = useState(null);

    // Context data
    React.useEffect(() => {
        axios.get(utils.server + 'get/home')
            .then(function (response) {
                setFeatured(response.data.featured);
                setHomeText(response.data.text);
                setServices(response.data.services);
                setCategories(response.data.categories);
                setIsAdmin(response.data.logged)
            })
    }, []);

    // Modal
    const modal = useCallback((param) => {
        switch (param) {
            case 'search':
                return <Modal title="Buscar en el sitio"><Search /></Modal>
            case 'map':
                return <Modal title="Ubicación"><Map /></Modal>
            case 'terms-almacenes':
                return <Modal title="Términos y condiciones"><TermsAlmacenes /></Modal>
            case 'terms-gremio':
                return <Modal title="Términos y condiciones"><TermsGremio /></Modal>
            case 'terms-consumidor-final':
                return <Modal title="Términos y condiciones"><TermsConsumidor /></Modal>
            default:
                return null;
        }
    }, []);

    // Methods
    const drawerTogglerHandler = (close = false) => {
        setShowDrawer(!showDrawer);
    }

    const parseDate = (date) => {
        const currentDate = new Date(date);
        const day = currentDate.getDate();
        const month = currentDate.getMonth(); //Be careful! January is 0 not 1
        const year = currentDate.getFullYear();
        const dateString = day + "-" + (month + 1) + "-" + year;

        return dateString;
    }

    // Transitions
    const { location } = useContext(__RouterContext);
    const transitions = useTransition(location, location => location.pathname, {
        from: { opacity: 0 },
        enter: { opacity: 1, position: "absolute" },
        leave: { opacity: 0, position: "absolute" },
        trail: 800,
        order: ['leave', 'enter', 'update'],
        onDestroyed: () => {
            window.scrollTo(0, 0)
        }
    })

    // Render Variables
    const sections = transitions.map(({ item: location, props, key }) => (
        <animated.div key={key} style={{ ...props, position: "relative", width: "100%", top: 0 }}>
            <Switch location={location}>
                <Route path="/proyectos/:page" component={Projects} exact />
                <Route path="/info/:slug" component={Info} exact />
                <Route path="/clientes" component={Clientes} exact />
                <Route path="/distribuidores" component={Distribuidores} exact />
                <Route path="/servicio/:slug" component={Info} exact />
                <Route path="/novedades/:page" component={News} exact />
                <Route path="/novedad/:slug" component={NewsPage} exact />
                <Route path="/contacto" component={Contact} exact />
                <Route path="/red-labadie" component={Work} exact />
                <Route path="/solicitar-presupuesto" component={Presupuesto} exact />
                <Route path="/capacitacion" component={Capacitacion} exact />
                <Route path="/servicio-tecnico" component={ServicioTecnico} exact />
                <Route path="/busqueda/:term/:page" component={SearchResult} exact />
                <Route path="/producto/:slug" component={Product} exact />
                <Route path="/productos/:slug/:page" component={ProductList} />
                <Route path="/areas/almacenes" component={BusinessAlmacenes} />
                <Route path="/areas/gremio" component={BusinessConsorcios} />
                <Route path="/areas/consumidor-final" component={BusinessConsumidor} />
                <Route path="/areas-de-negocio" component={AreasDeNegocio} />
                <Route path="/proyecto-detalle/:slug" component={ProjectDetail} exact />
                <Route path="/" component={Home} />
            </Switch>
            <Footer />
        </animated.div>
    ));

    return (
        <WindowDimensionsProvider>
            <UIProvider value={{
                drawerTogglerHandler, showDrawer, setShowDrawer,
                showModal, setShowModal,
                currentAccordion, setAccordion,
                currentInfoAccordion, setInfoAccordion,
                parseDate, itemsPerPage, isAdmin,
                formSubject, setFormSubject
            }}>
                <CatalogProvider value={{
                    currentCategory, setCurrentCategory,
                    currentProject, setCurrentProject,
                    featured, services, categories, homeText
                }}>
                    <div className="App">
                        <Layout>
                            {sections}
                        </Layout>
                        {modal(showModal)}
                    </div>
                </CatalogProvider>
            </UIProvider>
        </WindowDimensionsProvider>
    );
}

export default App;
