import React from 'react'
import utils from '../../../data/utils.json';
import './ProductList.scss';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const ProductItem = (props) => {
    const item = props.data;

    return (
        <div className="ProductItem">
            <div className="product__inner">
                <div className="product__image">
                    <img className=" img-fluid" src={utils.server + 'get/image/gallery_small/submissions/' + item.id + '/' + item.featuredImage} alt={item.title} />
                </div>
                <div className="product__info">
                    <h3 className="title"><NavLink to={"/producto/" + item.slug}>{item.title}</NavLink></h3>
                    <h5 className="sub-title">{item.subTitle}</h5>
                    <p className="short-description">{item.shortDescription}</p>
                    <div className="product__footer">
                        <div className="product__features check-list">
                            {ReactHtmlParser(item.features)}
                        </div>
                        <div className="product__links">
                            {(item.file) && <a target='_blank' href={utils.server + 'uploads/pdf/' + item.file}><span className="icon-pdf pdf"></span></a>}
                            <NavLink to={"/producto/" + item.slug} className="btn btn-outline text-primary hide-mobile">VER MÁS <span className="icon icon-plus"></span></NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <NavLink to={"/producto/" + item.slug} className="btn btn-outline text-primary show-mobile btn-block mt-3">VER MÁS <span className="icon icon-plus"></span></NavLink>
        </div >
    )
}

export default ProductItem