import React from 'react';
import './PreLoader.scss';

const PreLoader = () => {
    return (
        <div className="PreLoader">
            <span className="icon icon-spinner"></span>
        </div>
    )
}

export default PreLoader