import React, { useState, useContext, useEffect } from 'react';
import './Product.scss';
import SectionInfo from '../../HOC/SectionInfo/SectionInfo';
import { withRouter, useHistory } from 'react-router-dom';
import ProductCategoriesFull from '../ProductCategories/ProductCategoriesFull';
import CatalogContext from '../../../Context/CatalogContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';
import BtnIconBox from '../../UI/BtnIconBox/BtnIconBox';

const Product = ({ match, ...props }) => {

    const { slug: indexSlug } = match.params;
    const cCx = useContext(CatalogContext);
    const [data, setData] = useState(null);
    const history = useHistory();
    const [currentCategory, setCurrentCategory] = useState(null);

    useEffect(() => {
        axios.get(utils.server + 'get/post/' + indexSlug)
            .then(function (response) {
                setData(response.data);
                if (response.data.postCategories.length) setCurrentCategory(response.data.postCategories[0].slug);
            })
    }, []);

    const sectionRender = (data) ?
        <>
            <SectionInfo data={data} section="PRODUCTOS">
                <div className="header">
                    {(data.subTitle) &&
                        <h3>{data.subTitle}</h3>}
                    {(data.shortDescription) &&
                        <p className="product__short-desc">{data.shortDescription}</p>}
                    {(data.features) &&
                        <div className="product__features check-list">{ReactHtmlParser(data.features)}</div>}
                    <div className="mb-5 show-mobile">
                        <BtnIconBox clicked={() => history.push("/solicitar-presupuesto")} icon="money" variant="dark">SOLICITAR PRESUPUESTO</BtnIconBox>
                        {(data.file) && <a href={utils.server + 'uploads/pdf/' + data.file} target='_blank' className="btn btn-outline--02 text-primary mt-3">VER FICHA TÉCNICA <span className="icon icon-pdf pl-4"></span></a>}
                    </div>
                </div>
                <div className="body">
                    {ReactHtmlParser(data.body)}
                </div>
            </SectionInfo>
            <ProductCategoriesFull current={currentCategory} />
        </> : <PreLoader />;

    return (
        <div className="Product">
            {sectionRender}
        </div>
    )
}

export default withRouter(Product);