import React from 'react';
import './Map.scss';
import Iframe from 'react-iframe'

const Map = () => {
    return (
        <div className="Map">
            <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.195876019037!2d-58.442455684278976!3d-34.57390998046723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5c00621522d%3A0x74734231615fb1bf!2sLabadie%20Videosistemas!5e0!3m2!1ses-419!2sar!4v1590088288464!5m2!1ses-419!2sar"
                width="100%"
                height="500px"
                id="gMapsId"
                className="map"
                position="relative" />
        </div>
    )
}

export default Map