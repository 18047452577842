import React, { useState, useContext, useEffect } from 'react';
import './Contact.scss';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Form from '../../UI/Form/Form';
import Sidebar from './Sidebar';
import Iframe from 'react-iframe';
import axios from 'axios';
import utils from '../../../data/utils.json';

const Work = ({ match, ...props }) => {
    // const { slug: indexSlug } = match.params;
    const [data, setData] = useState(null);
    const tagMailSender = props.location.state?.tagMailSender;
    useEffect(() => {
        const fetchUrl = "get/info/133";
        axios.get(utils.server + fetchUrl)
            .then(function (response) {
                setData(response.data);
            })
    }, []);

    const formFields = {
        name: {
            "label": "Nombre y apellido",
            "type": "text",
            "required": true,
        },
        email: {
            "label": "Dirección de e-Mail",
            "type": "email",
            "required": true,
        },
        phone: {
            "label": "Número de teléfono",
            "type": "text",
            "required": true,
        },
        local: {
            "label": "Local a la calle o independiente",
            "type": "text",
            "required": false,
        },
        rubro: {
            "label": "Rubro",
            "type": "text",
            "required": true,
            "hint": 'CCTV - Videoportero® - Portero eléctrico - Alarmas - Control de Acceso, Etc.',
            "full": true
        },
        works: {
            "label": "Trabajos destacados",
            "type": "textarea",
            "required": false
        },
        comment: {
            "label": "Presentación",
            "type": "textarea",
            "required": false
        },
    }

    const send = <>Enviar formulario <span className="icon icon-cheveron-right icon-secondary"></span></>;
    const sended = <>
        <div className="sended alert alert-success">Mensaje enviado, le responderemos a la brevedad. Muchas gracias. <span className="icon-checkmark icon text-success"></span></div>
    </>;
    const error = <>
        Por favor revise los campos marcados con <strong>*</strong>, y tilde la casilla de verificación.
        <span className="icon-close icon-secondary text-white"></span>
    </>;

    const footer = <p>Los campos marcados con * son obligatorios</p>;

    // Form State
    const sectionRender = (data) ?
        <Form data={formFields} sended={sended} error={error} send={send} footer={footer} targetUrl={tagMailSender?tagMailSender:'work'} /> :
        <PreLoader />;

    const map = (data) ? <Iframe url={data.aux2}
        width="100%"
        height="600px"
        id="gMapsId"
        className="map"
        position="relative" /> : null;

    return (
        <div className="Contact">
            <WithTitle section='CONTACTO' title={'Red Labadie'} type="main-block--padding">
                <div className="contact__inner">
                    <div className="contact__main">
                        {sectionRender}
                    </div>
                    <div className="contact__sidebar p-0" style={{ backgroundColor: 'transparent' }}>
                        {/* <Sidebar /> */}
                        {map}
                    </div>
                </div>
                {/* <div className="info__map mt-5">
                    <Iframe url="https://www.google.com/maps/d/embed?mid=13CNODL91aQ8Yw_IeE_7pzVSVF64tUVtg"
                        width="100%"
                        height="400px"
                        id="gMapsId"
                        className="map"
                        position="relative" />
                </div> */}
            </WithTitle>
        </div>
    )
}

export default Work