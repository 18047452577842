import React from 'react';
import './Button.scss';

const Button = ({ children, type = "button", modifiers, action }) => {
    return (
        <button type={type} onClick={action} className={"Button " + modifiers}>
            {children}
        </button>
    )
}

export default Button