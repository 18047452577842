import React from 'react';
import './SubNav.scss';
import NavItem from '../NavItem/NavItem';

const SubNav = (props) => {
    return (
        <div className="SubNav">
            <NavItem>{props.name} <span className="icon icon-cheveron-down"></span></NavItem>
            <div className="list">
                {props.children}
            </div>
        </div>
    )
}

export default SubNav