import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import utils from '../../../../data/utils.json';
import { NavLink } from 'react-router-dom';

const NewsItem = (props) => {

    const data = props.data;
    const link = (data.type === 2) ? '/info/' + data.slug : '/novedad/' + data.slug;

    // TODO check image dimensions

    return (
        <div className="NewsItem">
            <div className="inner">
                <NavLink to={link}><img className="ni__image" src={utils.server + 'get/image/slide_med/submissions/' + data.id + '/' + data.featuredImage} alt={data.title} /></NavLink>
                <div className="ni__info">
                    {/* <p className="ni__date">{data.date}</p> */}
                    <h4 className="ni__title"><NavLink to={link}>{data.title}</NavLink></h4>
                    <div className="ni__description">{ReactHtmlParser(data.shortDescription)}</div>
                </div>
                <div className="ni__actions">
                    <NavLink to={link} className="btn-circle btn-dark-blue"><span className="icon icon-cheveron-right"></span></NavLink>
                </div>
            </div>
            <div className="ni__description--mobile">{ReactHtmlParser(data.shortDescription)}</div>
        </div>
    )
}

export default NewsItem