import React, { useState, useContext, useRef } from 'react';
import Slider from "react-slick";
// import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import './Home.scss';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import CatalogContext from '../../../Context/CatalogContext';
import Services from '../Services/Services';
import ProductCategoriesFull from '../ProductCategories/ProductCategoriesFull';
import NewsList from '../NewsList/NewsList';
import PreLoader from '../../UI/PreLoader/PreLoader';
import AreasDeNegocio from '../AreasDeNegocio/AreasDeNegocioInner';
import logoHome from '../../../assets/images/logo-horizontal.svg';

const Home = () => {

    const cCx = useContext(CatalogContext);
    const [featured, setFeatured] = useState(null);
    // const [services, setServices] = useState(null);
    const [homeText, setHomeText] = useState(null);
    const [categories, setCategories] = useState(null);
    const history = useHistory();

    const slider = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    // afterChangeHandler(currentSlide) {
    //     this.props.updateInitialSlide(currentSlide);
    // }

    React.useEffect(() => {
        setFeatured(cCx.featured);
        setHomeText(cCx.homeText);
        // setServices(cCx.services);
        setCategories((cCx.categories) ? cCx.categories.filter(item => item.featured === true) : null);
    }, [cCx]);

    const renderSlides = (featured !== null) ? featured.map((item) => {

        const slideImage = (item.listImage)
            ? 'get/image/slide/thumbnails/' + item.listImage
            : 'get/image/slide/submissions/' + item.id + '/' + item.featuredImage;

        let linkSlug = '';

        switch (item.type) {
            case 0:
                linkSlug = '/novedad/';
                break;
            case 2:
                linkSlug = '/servicio/';
                break;
            default:
                linkSlug = '/producto/';
        }

        return (
            <div
                className="slide slide__overlay"
                onClick={() => history.push(linkSlug + item.slug)}
                key={"featuredSlide-" + item.id}>
                {/* <div className="slide__info"> */}
                {/* <h2 className="slide__title" onClick={() => history.push((item.type === 0) ? '/novedad/' + item.slug : '/producto/' + item.slug)}>{item.title}</h2> */}
                {/* <div className="slide__actions">
                        {item.shortDescription && <div className="short-description">
                            {ReactHtmlParser(item.shortDescription)}
                        </div>}
                        <div className="link hide-mobile">
                            <NavLink to={
                                (item.type === 0) ?
                                    '/novedad/' + item.slug :
                                    '/producto/' + item.slug
                            } className="btn btn-light">VER MÁS</NavLink>
                        </div>
                    </div> */}
                {/* </div> */}
                <img className="slide__bg" src={utils.server + slideImage} alt={item.title} />
            </div>
        )
    }) : <PreLoader />;

    const pager = (featured !== null) ? (
        (featured.length > 1) ?
            <div className="sliderPager">
                <div className="slider__inner">
                    <button className="arrow" id="slider-left" onClick={slider.current.slickPrev}><span className="icon icon-cheveron-left"></span></button>
                    {
                        featured.map((item, index) =>
                            <button
                                onClick={() => slider.current.slickGoTo(index)}
                                className={(index === currentSlide) ? 'active' : null}
                                key={"pager-" + index}
                                id={"pager-" + index}>{("0" + (index + 1)).substr(-4, 4)}</button>)
                    }
                    <button className="arrow" id="slider-right" onClick={slider.current.slickNext}><span className="icon icon-cheveron-right"></span></button>
                </div>
            </div>
            : null
    ) : null;

    const settings = {
        dots: true,
        infinite: true,
        speed: 800,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        pauseOnDotsHover: true,
        arrows: false,
        // cssEase: 'cubic-bezier(0.665, 0.005, 0.330, 1)',
        // customPaging: function (i) {
        //     return (
        //         <a>{i + 1}</a>
        //     );
        // }
    }

    const homeBlock = (homeText) ? (
        <div className="home-line">
            {/* {homeText.subTitle && <h2>{homeText.subTitle}</h2>} */}
            {<img className='LogoHorizontal' src={logoHome} alt="Labadie by Fermax" />}
            {homeText.shortDescription && <p>{homeText.shortDescription}</p>}
            {homeText.aux1 && <NavLink
                to={{pathname:((homeText.aux2) ? homeText.aux2 : '/'), state: { tagMailSender: 'home-red-labadie' } }}
                className="btn btn-outline home-btn"
            >{homeText.aux1} <span className="icon icon-cheveron-right"></span></NavLink>}
        </div>
    ) : null;

    return (
        <div className="Home">
            <Slider
                ref={slider}
                {...settings}
                afterChange={(index) => setCurrentSlide(index)}
                className="FeaturedSlider">
                {renderSlides}
            </Slider>
            {/* {pager} */}
            {homeBlock}
            <ProductCategoriesFull />
            <div className='container-fluid bg-light'>
                <h1 className='text-center pt-5 text-primary font-weight-bold'>ÁREAS DE NEGOCIO</h1>
                <AreasDeNegocio />
            </div>
            <NewsList />
        </div>
    )
}

export default Home