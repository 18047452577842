import React from 'react';
import './Logo.scss';
import logoFile from '../../../assets/images/logo.svg';
import { withRouter } from 'react-router-dom';
import UIContext from '../../../Context/UIContext';

const Logo = (props) => {

    const UICx = React.useContext(UIContext);

    const handleClick = () => {
        props.history.push('/');
        UICx.setShowDrawer(false);
    }

    return (
        <div className="Logo" onClick={handleClick}>
            <img src={logoFile} className="img-fluid" alt="Labadie" />
        </div>
    );
};

export default withRouter(Logo);
