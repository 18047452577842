import React, { useEffect, useState } from 'react';
import './SearchResult.scss';
import '../ProductList/ProductList.scss';
import '../NewsList/NewsList.scss';
import './SearchResult.scss';
import axios from 'axios';
import utils from '../../../data/utils.json';
import { NavLink, withRouter, useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import UIContext from '../../../Context/UIContext';

import WithTitle from '../../HOC/WithTitle/WithTitle';
import ProductItem from '../ProductList/ProductItem';
import NewsItem from '../NewsList/NewsItem/NewsItem';
import PreLoader from '../../UI/PreLoader/PreLoader';

const SearchResult = ({ match, ...props }) => {

    const { term: urlTerm } = match.params;
    const { page: currentPage } = match.params;

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('...');

    const [totalItems, setTotalItems] = useState(0);
    const [url, setUrl] = useState(null);
    const history = useHistory();

    useEffect(() => {
        axios.get(utils.server + 'get/search/' + urlTerm + '/' + currentPage)
            .then(function (response) {
                setList(response.data.data);
                setLoading(false);
                setTotalItems(response.data.total * 10);
            })
        setTitle(urlTerm);
        setUrl('/busqueda/' + urlTerm);
    }, []);

    const renderRows = (list.length) ?
        list.map(
            item => (item.type === 1) ?
                <ProductItem key={item.id} data={item} /> :
                <NewsItem key={item.id} data={item} />
        ) : <div className="no-results">
            <p>No se encontraron resultados para los términos de búsqueda ingresados.</p>
        </div>;

    const renderBody = (loading) ? <PreLoader /> : <div className="list-inner">{renderRows}</div>;

    return (
        <div className="SearchResult ProductList NewsList">
            <WithTitle
                section='BÚSQUEDA'
                title={
                    <>Resultados para: <strong>{title}</strong></>
                }>
                {renderBody}
                <Pagination
                    activePage={parseInt(currentPage)}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    hideNavigation={true}
                    hideFirstLastPages={true}
                    innerClass={"Pagination"}
                    onChange={(n) => history.push(url + '/' + n)}
                />
            </WithTitle>
        </div>
    )
}

export default withRouter(SearchResult);