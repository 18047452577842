import React, { useState, useEffect } from 'react';
import './NewsList.scss';
import utils from '../../../data/utils.json';
import axios from 'axios';
import { NavLink } from 'react-router-dom';

import NewsItem from './NewsItem/NewsItem';

const NewsList = (props) => {

    const [news, setNews] = useState(null)
    const current = (props.current) ? props.current.id : null;

    useEffect(() => {
        axios.get(utils.server + 'find/last-news')
            .then(function (response) {
                setNews(response.data.filter(item => item.id !== current));
            })
    }, []);

    const renderNews = (news !== null) ?
        news.map((item, index) => {
            return (index < 3) ? <NewsItem key={'news-' + item.id} data={item} /> : null
        }) :
        null;

    return (
        <div className="NewsList">
            <h3 className="PageTitle">{(current) ? 'ÚLTIMAS ENTRADAS' : 'NOVEDADES'}</h3>
            <div className="list-wrapper">
                {renderNews}
            </div>
            <div className="text-center pt-4 pb-5">
                <NavLink to={'/novedades/1'} className="btn-outline btn btn-light">VER NOVEDADES ANTERIORES</NavLink>
            </div>
        </div>
    )
}

export default NewsList