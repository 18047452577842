import React, { useState, useContext, useEffect } from 'react';
import './Info.scss';
import { withRouter, useHistory } from 'react-router-dom';
import UIContext from '../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Iframe from 'react-iframe';
import BtnIconBox from '../../UI/BtnIconBox/BtnIconBox';

const Info = (props) => {
    const uiCx = useContext(UIContext);
    const [data, setData] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const fetchUrl = "get/info/133";
        axios.get(utils.server + fetchUrl)
            .then(function (response) {
                setData(response.data);
            })
    }, []);


    const sectionRender = (data) ?
        <>
            <div className="SectionInfo Distribuidores">
                <WithTitle section={"EMPRESA"} title={data.title} />
                <div className="info__inner main-block--padding">
                    <div className="info__main">
                        <div className="body">
                            {ReactHtmlParser(data.body)}
                        </div>
                        <div className="d-flex my-3 info__btn">
                            <BtnIconBox clicked={() => history.push( {pathname:"/red-labadie", state: { tagMailSender: 'empresa-distribuidores' } })} icon="cheveron-right">{data.aux1}</BtnIconBox>
                        </div>
                    </div>
                    <div className="info__images info__map">
                        <Iframe url={data.aux2}
                            width="100%"
                            height="500px"
                            id="gMapsId"
                            className="map"
                            position="relative" />
                    </div>
                </div>
            </div>
        </> : <PreLoader />;

    return (
        <div className="Info">
            {sectionRender}
        </div>
    )
}

export default withRouter(Info);