import React, { useState, useContext, useEffect } from 'react';
import './NewsPage.scss';
import SectionInfo from '../../HOC/SectionInfo/SectionInfo';
import { withRouter } from 'react-router-dom';
import NewsList from '../NewsList/NewsList';
import UIContext from '../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';

const NewsPage = ({ match, ...props }) => {
    const { slug: indexSlug } = match.params;
    const uiCx = useContext(UIContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(utils.server + 'get/post/' + indexSlug)
            .then(function (response) {
                setData(response.data);
            })
    }, []);

    const sectionRender = (data) ?
        <>
            <SectionInfo data={data} section="NOVEDADES">
                <div className="header">
                    <div className="date"><em>Publicado el {uiCx.parseDate(data.date)}</em></div>
                    {(data.subTitle) &&
                        <h3>{data.subTitle}</h3>}
                    {(data.shortDescription) &&
                        <p>{data.shortDescription}</p>}
                </div>
                <div className="body">
                    {ReactHtmlParser(data.body)}
                </div>
            </SectionInfo>
            <NewsList current={data} />
        </> : <PreLoader />;

    return (
        <div className="NewsPage">
            {sectionRender}
        </div>
    )
}

export default withRouter(NewsPage);