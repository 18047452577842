import React, { useContext, useState, useEffect } from 'react';
import './MainNav.scss';
import NavItem from '../../UI/NavItem/NavItem';
import SubNav from '../../UI/SubNav/SubNav';
import BtnCollapse from '../../UI/BtnCollapse/BtnCollapse';
import CatalogContext from '../../../Context/CatalogContext';

const MainNav = (props) => {

    const UICx = useContext(CatalogContext);
    const NavMenu = (props.mobile) ? BtnCollapse : SubNav;

    const [categories, setCategories] = useState(null);
    const [services, serServices] = useState(null);
    const [forms, setForms] = useState(null);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        setCategories(UICx.categories);
        serServices(UICx.services);
        setForms([
            { slug: 'contacto', name: 'Consultas generales' },
            { slug: 'solicitar-presupuesto', name: 'Solicitar presupuesto' },
            { slug: 'servicio-tecnico', name: 'Solicitar servicio técnico' },
            { slug: 'red-labadie', name: 'Red Labadie' },
        ]);
        setInfo([
            { slug: 'info/nosotros', name: 'Sobre nosotros' },
            { slug: 'proyectos/1', name: 'Proyectos destacados' },
            { slug: 'clientes', name: 'Clientes' },
            { slug: 'distribuidores', name: 'Distribuidores' },
            // { slug: 'postulaciones', name: 'Trabajá con nosotros' },
        ]);
    }, [UICx.categories, UICx.services])

    const categoryItems = categories ? categories.map((item) => {
        return <NavItem key={"form-" + item.id} target={"/productos/" + item.slug + "/1"} section="producto">{item.name}</NavItem>
    }) : null;
    const serviceItems = services ? services.map((item) => {
        return <NavItem key={"form-" + item.id} target={"/servicio/" + item.slug} section="servicio">{item.title}</NavItem>
    }) : null;
    const formItems = forms ? forms.map((item, index) => {
        return <NavItem onClick={() => UICx.setFormSubject('')} key={"form-" + index} target={"/" + item.slug} section="contacto">{item.name}</NavItem>
    }) : null;
    const infoItems = info ? info.map((item, index) => {
        return <NavItem key={"form-" + index} target={"/" + item.slug} section="info">{item.name}</NavItem>
    }) : null;

    return (
        <nav className="MainNav">
            {/* <NavItem target="/">Inicio</NavItem> */}
            {/* <NavItem target="/empresa">Empresa</NavItem> */}
            <NavMenu name="Sistemas">
                {categoryItems}
            </NavMenu>
            <NavMenu name="Servicios">
                {serviceItems}
            </NavMenu>
            <NavMenu name="Empresa">
                {infoItems}
            </NavMenu>
            <NavMenu name="Contacto">
                {formItems}
            </NavMenu>
            <NavItem target="/novedades/1" section="novedad">Novedades</NavItem>
            <NavMenu name="Áreas de Negocio">
                <NavItem target={"/areas/almacenes"} section="negocio" >Almacenes</NavItem>
                <NavItem target={"/areas/gremio"} section="negocio">Gremio</NavItem>
                <NavItem target={"/areas/consumidor-final"} section="negocio">Consumidor Final</NavItem>
            </NavMenu>
        </nav>
    )
}

export default MainNav