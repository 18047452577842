import React, { useState, useContext, useEffect } from 'react';
import './Info.scss';
import SectionInfo from '../../HOC/SectionInfo/SectionInfo';
import { withRouter } from 'react-router-dom';
import NewsList from '../NewsList/NewsList';
import UIContext from '../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';
import History from '../History/History';

const Info = ({ match, ...props }) => {
    const { slug: indexSlug } = match.params;
    const uiCx = useContext(UIContext);
    const [data, setData] = useState(null);

    useEffect(() => {
        let fetchUrl = '';
        switch (indexSlug) {
            case 'clientes':
                fetchUrl = 'get/info/51';
                break;
            case 'nosotros':
                fetchUrl = 'get/info/35';
                break;
            default:
                fetchUrl = 'get/post/' + indexSlug;
        }

        axios.get(utils.server + fetchUrl)
            .then(function (response) {
                setData(response.data);
          
            })
    }, []);

    let subTitle = "INFORMACIÓN";

    if (data) {
        switch (data.type) {
            case 0:
                subTitle = 'NOVEDADES';
                break;
            case 1:
                subTitle = 'PRODUCTOS';
                break;
            case 2:
                subTitle = 'SERVICIOS';
                break;
            case 3:
                subTitle = 'EMPRESA';
                break;
        }
    }

    const sectionRender = (data) ?
        <>
            <SectionInfo data={data} section={subTitle} tagMailSender={indexSlug}>
                <div className="header">
                    {(data.subTitle) &&
                        <h3>{data.subTitle}</h3>}
                    {(data.shortDescription) &&
                        <p>{data.shortDescription}</p>}
                </div>
                <div className="body">
                    {ReactHtmlParser(data.body)}
                </div>
            </SectionInfo>
            {indexSlug === 'nosotros' && <History />}
        </> : <PreLoader />;

    return (
        <div className="Info">
            {sectionRender}
        </div>
    )
}

export default withRouter(Info);