import React from 'react';
import utils from '../../../data/utils.json';
import './AreasDeNegocio.scss';
import AreasDeNegocioInner from './AreasDeNegocioInner';
import WithTitle from '../../HOC/WithTitle/WithTitle';

const AreasDeNegocio = () => {
    return (<>
        <WithTitle section={"EMPRESA"} title={'ÁREAS DE NEGOCIO'} />
        <div className='px-2 mb-5'>
            <AreasDeNegocioInner />
        </div>
    </>
    )
}

export default AreasDeNegocio