import React from 'react';
import './Modal.scss';
import UIContext from '../../../Context/UIContext';
import Backdrop from '../Backdrop/Backdrop';
import { useSpring, animated } from 'react-spring';

const Modal = (props) => {

    const UICx = React.useContext(UIContext);

    const fadeIn = useSpring({
        from: {
            marginTop: "-100vh"
        },
        to: {
            marginTop: "0vh"
        }
    });

    const handleClick = () => {
        UICx.setShowModal(false);
    }

    return (
        <Backdrop>
            <animated.div style={fadeIn} className="Modal">
                <div className="inner">
                    <header>
                        <h4 className="title">{props.title}</h4>
                        <button onClick={handleClick} className="btn icon-close"></button>
                    </header>
                    <section>
                        {props.children}
                    </section>
                </div>
                <div className="interaction" onClick={handleClick}></div>
            </animated.div>
        </Backdrop>
    )
}

export default Modal