import React, { useState, useEffect, useRef, useContext } from 'react';
import './SectionInfo.scss';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import utils from '../../../data/utils.json';
import Slider from "react-slick";
import BtnIconBox from '../../UI/BtnIconBox/BtnIconBox';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';
import SliderArrows from '../../UI/SliderArrows/SliderArrows';
import UIContext from '../../../Context/UIContext';
import YouTube from "react-youtube";

const SectionInfo = (props) => {
    const data = props.data;
    const slider = useRef(null);
    const history = useHistory();
    const UICx = useContext(UIContext);
    const pathsTagMailer = ['contrato-de-servicio-y-mantenimiento','sistema-de-videoportero-r','control-de-acceso','conectividad','capacitaciones']
    const tagMailSender =  props.tagMailSender??null;
    const gallery = data.postPhoto.map(item => <div key={"gallery-" + item.id} className="gallery-item">
        <img className="img-fluid" src={utils.server + "get/image/medium/submissions/" + data.id + "/" + item.file} alt={data.title} />
    </div>);


    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: true,
        arrows: false
    }

    const opts = {
        height: '270px',
        width: '480px',
        playerVars: {
            showinfo: 0,
            controls: 0,
            modestbranding: 1,
            rel: 0
        }
    };

    let video1 = null;
    if (data.aux1 && data.type === 0) {
        const videoId = data.aux1.split('=').reverse();
        video1 = <div className="video-container mb-5"><YouTube
            videoId={videoId[0]}
            opts={opts}
        /></div>
    }

    let video2 = null;
    if (data.aux2 && data.type === 0) {
        const videoId = data.aux2.split('=').reverse();
        video2 = <div className="video-container mt-5"><YouTube
            videoId={videoId[0]}
            opts={opts}
        /></div>
    }

    const gotToQuote = () => {
        UICx.setFormSubject(data.title);
        if(tagMailSender && pathsTagMailer.indexOf(tagMailSender) > -1 ){
            if(tagMailSender === 'capacitaciones'){
                history.push( { pathname: '/capacitacion', state: { tagMailSender: tagMailSender } });            
            }else{
                history.push( { pathname: '/solicitar-presupuesto', state: { tagMailSender: tagMailSender } });        
            }
        }else{
            history.push("/solicitar-presupuesto");
        }
    }

    const imageFooter = (data.type === 1 || data.type === 2)
        ? <>
            <div className="d-flex justify-content-center mb-5 info__btn flex-wrap">
                <BtnIconBox clicked={gotToQuote} icon="money" variant="dark"> {tagMailSender && tagMailSender === 'capacitaciones'?"Inscribite Acá": "SOLICITAR PRESUPUESTO"}</BtnIconBox>
                {(data.file) && <a href={utils.server + 'uploads/pdf/' + data.file} target='_blank' className="btn btn-outline--02 text-primary mt-3">VER FICHA TÉCNICA <span className="icon icon-pdf pl-4"></span></a>}
            </div>
        </>
        : <div className="info__legend">{ReactHtmlParser(data.features)}</div>

    const goBack = (data.type === 1 || data.type === 0) ? true : false;

    return (
        <div className="SectionInfo">
            <WithTitle section={props.section} title={data.title} goBack={goBack} />
            <div className="info__inner main-block--padding">
                <div className="info__main">
                    {props.children}
                </div>
                <div className="info__images">
                    {video1}
                    <div className="slider-wrapper">
                        <SliderArrows slider={slider} items={data.postPhoto}>
                            <Slider
                                ref={slider}
                                {...settings}>
                                {gallery}
                            </Slider>
                        </SliderArrows>
                    </div>
                    {imageFooter}
                    {video2}
                </div>
            </div>
        </div>
    )
}

export default SectionInfo