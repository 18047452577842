import React, { useEffect, useState } from 'react';
import './News.scss';
import '../NewsList/NewsList.scss';
import axios from 'axios';
import utils from '../../../data/utils.json';
import { NavLink, withRouter, useHistory } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import UIContext from '../../../Context/UIContext';

import WithTitle from '../../HOC/WithTitle/WithTitle';
import ProductItem from '../ProductList/ProductItem';
import NewsItem from '../NewsList/NewsItem/NewsItem';
import PreLoader from '../../UI/PreLoader/PreLoader';

const News = ({ match, ...props }) => {

    const { page: currentPage } = match.params;

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);

    const [totalItems, setTotalItems] = useState(0);
    const history = useHistory();

    useEffect(() => {
        axios.get(utils.server + 'get/news/' + currentPage)
            .then(function (response) {
                setList(response.data.data);
                setLoading(false);
                setTotalItems(response.data.total * 10);
            })
    }, []);

    const renderRows = (list.length) ?
        list.map(
            item => <NewsItem key={item.id} data={item} />
        ) : <div className="no-results">
            <p>No se encontraron resultados para los términos de búsqueda ingresados.</p>
        </div>;

    const renderBody = (loading) ? <PreLoader /> : <div className="list-inner">{renderRows}</div>;

    return (
        <div className="News NewsList">
            <WithTitle
                section='NOVEDADES'
                title="TODAS LAS ENTRADAS">
                {renderBody}
                <Pagination
                    activePage={parseInt(currentPage)}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    hideNavigation={true}
                    hideFirstLastPages={true}
                    innerClass={"Pagination"}
                    onChange={(n) => history.push('/novedades/' + n)}
                />
            </WithTitle>
        </div>
    )
}

export default withRouter(News);