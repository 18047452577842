import React from 'react';
import './WithTitle.scss';
import { useHistory } from 'react-router-dom';

const WithTitle = (props) => {

    const sectionClass = props.type ? props.type : 'main-block';
    const history = useHistory();

    return (
        <div className="WithTitle">
            <h3 className="section">{props.section}</h3>
            <h1 className="PageTitle">
                {props.goBack && <div className="wrap"><button className="btn-circle d-inline-block icon-cheveron-left" onClick={history.goBack}>
                </button></div>}
                {props.title}
            </h1>
            {props.description && <p className="description">{props.description}</p>}
            {(props.children) && <section className={sectionClass}>
                {props.children}
            </section>}
        </div>
    )
}

export default WithTitle