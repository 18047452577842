import React, { useRef, useContext, useState, useEffect } from 'react';
import './Search.scss';
import { NavLink, useHistory } from 'react-router-dom'
import UIContext from '../../../Context/UIContext';

const Search = () => {
    let history = useHistory();
    const UICx = useContext(UIContext);
    const searchInput = useRef(null);
    const [values, setValues] = useState({
        term: ''
    });

    const handleChange = name => (event, newValue) => {
        const nextVal = (newValue === undefined) ? event.target.value : newValue;
        setValues({ ...values, [name]: nextVal });
    };

    const handleSearch = (e) => {

        e.preventDefault();

        if (values.term.length > 1) {
            UICx.setShowModal(false);
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (searchInput) searchInput.current.focus();
    }, []);

    const handleSubmit = (e) => {
        if (handleSearch(e)) history.push("/busqueda/" + values.term + "/1");
    }

    return (
        <div className="Search">
            <p>Se mostrarán productos o articulos que coincidan con sus términos de búsqueda.</p>
            <form onSubmit={handleSubmit} className="search-input-wrapper">
                <input ref={searchInput} type="text" value={values.term} onChange={handleChange('term')} className="ui-form-field font-weight-bold" placeholder="TÉRMINOS DE BÚSQUEDA" />
                <button type="submit" onClick={handleSubmit} className="btn btn-primary">BUSCAR</button>
            </form>
        </div>
    )
}

export default Search