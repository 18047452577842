import React from 'react';
import './TopNav.scss';
import Logo from '../../UI/Logo/Logo';
import MainNav from '../MainNav/MainNav';
import BtnIcon from '../../UI/BtnIcon/BtnIcon'
import UIContext from '../../../Context/UIContext';
import { useWindowDimensions } from '../../Utils/WindowDimensionsProvider';
import NavData from '../../Layout/MainNav/NavData';

const TopNav = () => {
    const UICx = React.useContext(UIContext);
    const vpSize = useWindowDimensions();

    return (
        <div className="TopNav">
            <div className="start">
                <Logo />
                <MainNav />
            </div>
            <div className="end">
                <NavData />
                <BtnIcon icon="search" clicked={() => UICx.setShowModal('search')} />
                {
                    (vpSize.width < vpSize.breakpoints.lg) &&
                    <BtnIcon icon={(UICx.showDrawer) ? "close" : "menu"} clicked={UICx.drawerTogglerHandler} />
                }
            </div>
        </div>
    )
}

export default TopNav