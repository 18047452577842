import React, { useContext, useState, useEffect } from 'react';
import PreLoader from '../../../UI/PreLoader/PreLoader';
import Form from '../../../UI/Form/Form';
import BusinessInner from '../BusinessInner/BusinessInner';
import UIContext from '../../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../../data/utils.json';
import './BusinessAlmacenes.scss';


const BusinessAlmacenes = () => {
    // const { slug: indexSlug } = match.params;
    const [mapData, setMapData] = useState(null);

    useEffect(() => {
        axios.get(utils.server + 'get/info/164')
            .then(function (response) {
                setMapData(response.data);
            })
    }, []);

    // ========================================================================
    // Form
    // ========================================================================
    const [data, setData] = useState(null);
    const UICx = useContext(UIContext);

    React.useEffect(() => {
        setData(true);
    });

    // ========================================================================
    // Hero
    // ========================================================================
    const hero = {
        section: "CONSUMIDOR FINAL",
        title: "SUMATE A NUESTRA COMUNIDAD",
        subtitle: "Para estar siempre conectado y a la vanguardia tecnológica."
    }

    // ========================================================================
    // Info
    // ========================================================================
    const info = {
        section: hero.section,
        body: <div className='mt-md-5'>
            <p>¿Perdiste un delivery porque tu portero no funcionó? </p>
            <p>¿Tenes tu portero roto pero no tenes tiempo para el service? </p>
            <p>¿No estás nunca en tu departamento y solo dejarías entrar a tu electricista de confianza?</p>
            <p>Estas a un click de conseguir nuestro <strong>teléfono universal VEO F2 </strong>en un Almacén de tu zona o a través de un técnico sugerido que, si queres, también te lo instala.</p>
            <p>Este teléfono<strong>, minimalista y funcional</strong>, sustituye a la mayoría de los teléfonos estándar de otras marcas. Está fabricado en plástico de alto impacto con protección ultravioleta que lo hace más resistente a la luz solar. <strong>Minimalista, funcional y en línea con tu estilo.</strong> </p>
        </div>
    }

    // ========================================================================
    // Beneficios
    // ========================================================================
    const beneficios = {
        title: <>SUMATE A LA RED DE REVENDEDORES <br />DEL TELÉFONO UNIVERSAL VEO F2 <br />DE <span className='text-primary'>LABADIE BY FERMAX</span></>,
        accordion: [
            {
                title: "Asesoramiento Técnico",
                body: <p>Recibí asesoramiento de nuestros técnicos especializados que te ayudarán a elegir el producto más adecuado para tus necesidades.</p>
            },
            {
                title: "Newsletter - Optativo",
                body: <p>Si estás interesado en mantenerte a la vanguardia y conectado en todo momento, anótate para recibir nuestro Newsletter. Nuestra amplia gama de productos va desde lo más simple hasta conectividad y domótica.</p>
            }
        ]
    }

    // ========================================================================
    // Mapas
    // ========================================================================
    const maps = mapData ? [
        {
            title: 'Mapa Red de Almacenes',
            map: mapData.aux1
        },
        {
            title: 'Mapa Instaladores Sugeridos',
            map: mapData.aux2
        }
    ] : null;

    // ========================================================================
    // Formulario
    // ========================================================================
    const formFields = {
        name: {
            "label": "Nombre y Apellido",
            "type": "text",
            "required": false,
            "full": true
        },
        email: {
            "label": "Dirección de e-Mail",
            "type": "email",
            "required": true,
        },
        phone: {
            "label": "Celular",
            "type": "number",
            "required": false,
        },
        address: {
            "label": "Casa o Depto",
            "type": "text",
            "required": false,
        },
        consorcio: {
            "label": "Datos Consorcio/Administración",
            "type": "text",
            "required": false,
        }
    }

    const send = <>Enviar formulario <span className="icon icon-cheveron-right icon-secondary"></span></>;
    const sended = <>
        <div className="sended alert alert-success">Mensaje enviado, le responderemos a la brevedad. Muchas gracias. <span className="icon-checkmark icon text-success"></span></div>
    </>;
    const error = <>
        Por favor revise los campos marcados con <strong>*</strong>, y tilde la casilla de verificación.
    </>;

    const footer = <p>Los campos marcados con * son obligatorios</p>;

    // Form State
    const formRender = (data) ?
        <Form formBusiness data={formFields} sended={sended} error={error} send={send} footer={footer} targetUrl={'negocios-consumidor-final'} /> :
        <PreLoader />;

    // throw new Error("Sección no disponible momentaneamente");

    // ========================================================================
    // Component body
    // ========================================================================
    return (
        <div className="BusinessAlmacenes Contact">
            <BusinessInner hero={hero} info={info} beneficios={beneficios} slug={'consumidor'} maps={maps} mapAction={() => UICx.setShowModal('terms-consumidor-final')}>
                {formRender}
            </BusinessInner>
        </div>
    )
}

export default BusinessAlmacenes