import React, { useState, useEffect } from 'react';
import utils from '../../../data/utils.json';
import './SliderArrows.scss';

const SliderArrows = (props) => {
    const slider = props.slider;
    const items = props.items;
    const [sliderArrows, setSliderArrows] = useState(null);

    const itemsPerPage = (props.itemsPerPage) ? (props.itemsPerPage - 1) : 1;

    useEffect(() => {
        const sliderArrows = (items) ? (
            (items.length > itemsPerPage) ?
                <div className="sliderPager">
                    <button className="arrow" id="slider-left" onClick={slider.current.slickPrev}><span className="icon icon-cheveron-left"></span></button>
                    <button className="arrow" id="slider-right" onClick={slider.current.slickNext}><span className="icon icon-cheveron-right"></span></button>
                </div>
                : null
        ) : null;
        setSliderArrows(sliderArrows);
    }, [items, props.itemsPerPage]);

    return (
        <div className="SliderArrows">
            {props.children}
            {sliderArrows}
        </div>
    )
}

export default SliderArrows