import React, { useState, useEffect, useRef } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import utils from '../../../data/utils.json';
import Slider from "react-slick";
import SliderArrows from '../../UI/SliderArrows/SliderArrows';

const ProjectItem = (props) => {
    const data = props.data;
    const gallery = data.projectPhoto.map(item => <div key={"gallery-" + item.id} className="gallery-item">
        <img className="img-fluid" src={utils.server + "get/image/slide_med/projects/" + data.id + "/" + item.file} alt={data.title} />
    </div>);

    const slider = useRef(null);
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        pauseOnHover: true,
        arrows: false
    }

    return (
    
            <div className='col-12 col-sm-6 col-md-3'>
                <div className="project-item">
                    <div className="project-image">
                        <Link to={{pathname:'/proyecto-detalle/'+data.slug, state :{data: data}}}>
                        <img className="img-fluid" src={utils.server + "get/image/avatar/projects/" + data.id + "/" + data.featuredImage} alt={data.title} />
                        </Link> 
                    </div>
                    <div className="project-title"><h3> <Link to={{pathname:'/proyecto-detalle/'+data.slug, state :{data: data}}}>{data.title.split("-")[0]}</Link>  </h3></div>
                    <div className="project-typeSystem"><h4> <Link to={{pathname:'/proyecto-detalle/'+data.slug, state :{data: data}}}>{data.systemType}</Link> </h4></div>
                </div>
            </div>

    )
}

export default ProjectItem