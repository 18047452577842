import React from 'react';
import './Terms.scss';

const Terms = () => {
    return (
        <div className="Terms">
            <p>Al completar todos los datos solicitados en nuestro formulario de inscripción, el cliente acepta en su totalidad los términos y condiciones descriptos para cada beneficio concedido por Labadie Videosistemas S.A.C.I. (en adelante “la Empresa”) y en consecuencia se compromete a actuar en virtud de los mismos. Los datos provistos serán tratados con absoluta confidencialidad.</p>



            <p>La Empresa se reserva el derecho de modificar estos términos a su entera discreción por lo que sugiere a sus clientes que los revisen periódicamente. </p>



            <ul>
                <li><p><b>Recibe un teléfono en COMODATO:</b> <i>el Almacén se compromete a exhibir el teléfono VEO F2 en un lugar visible para sus clientes. La vigencia del presente Comodato es de 10 días corridos. El Comodatario se compromete a no introducir modificaciones en el teléfono VEO F2, su packaging y/o documentación adjunta bajo ningún concepto y, de no estar interesado en su comercialización, a restituir a la empresa el teléfono, su packaging, el certificado de garantía y especificaciones técnicas en el mismo estado y condiciones en que lo recibió. Caso contrario abonará el teléfono a un precio de U$S 22.- + IVA. El Comodatario se compromete a respetar la finalidad del comodato según lo pactado y a consultar al Comodante en cualquier situación específica que pudiere presentarse. El Comodatario se compromete a no ceder el uso del teléfono, su packaging y la documentación adjunta a terceros. El Comodatario se compromete a comunicarse con el Comodante antes de la finalización de la vigencia del Comodato para restituir el teléfono en Comodato de no estar interesado en su comercialización. Previa finalización de la vigencia estipulada para el Comodato, el Comodante se compromete a comunicarse con el almacén para evaluar interés y/o retirar el teléfono.</i></p>

                </li>
            </ul>



            <ul>
                <li><p><b>5% descuento adicional en la primera compra:</b> <i>oferta efectiva aplicando el código una vez que la Empresa valide los datos provistos y vigente siempre que la orden de compra se presente dentro de los 60 corridos días posteriores a dicha validación de datos. La empresa enviará un mail de confirmación de alta del cliente a la casilla de correo electrónico provista y la fecha de dicho mail será considerada la fecha de validación y entrada en vigencia del código. Es por ello que resulta imprescindible que los datos sean válidos y verdaderos y hayan sido ingresados de manera exacta y precisa.</i></p>
                    <p><i>Descuento no acumulable con otras promociones. Compra mínima 5 unidades.</i></p>



                    <p><i>Envío sin cargo solo en la primera compra y para compras de 40 unidades o más dentro de un rango de 15 (quince) kilómetros a la redonda del domicilio comercial de la empresa. Envío sin cargo solo en la primera compra y para compras de 80 unidades o más en todo CABA.</i></p>

                    <p><i>Condiciones y plazos de entrega: la Empresa no procesará ningún pedido hasta no haberse acreditado el pago total de la compra.</i></p>



                    <p><i>Confirmada la disponibilidad de stock, la Empresa se compromete a tener disponible el producto para su entrega en su depósito ubicado en Zapata 279 o envío según condiciones antes estipuladas dentro de los 15 días corridos posteriores a la confirmación del pago total.</i></p>



                    <p><i>Todos los productos LABADIE by FERMAX cuentan con una garantía de 1 año que entrará en vigencia a la fecha de entrega en depósito o envío a domicilio del comprador.</i></p>



                    <p><i>La Empresa se reserva el derecho a cambiar los precios en cualquier momento y sin previo aviso, con posterior notificación vía e-mail.</i></p>



                    <p><i>Política de Devoluciones y Reembolsos</i></p>

                    <p><i>La Empresa aceptará la devolución del producto y su reembolso siempre y cuando no haya alteraciones en la electrónica, el producto se encuentre en las mismas buenas condiciones en que fue entregado con su packaging original y certificado de garantía.</i></p>



                    <p><i>El reembolso se acreditará en pesos utilizando la tasa de cambio del banco ICBC del mismo día en que se acreditó el pago.</i></p>



                    <p><i>Las devoluciones y reembolsos se aceptarán dentro de los primeros 15 días corridos posteriores a la compra. </i></p>



                    <p><i>Esta oferta tendrá una vigencia de 60 días corridos.</i></p>
                </li>
            </ul>







            <ul>
                <li><p><b>Acceso a capacitaciones gratuitas sobre nuestras líneas de productos:</b> <i>para acceder a estas capacitaciones se requiere un conocimiento mínimo: electricista matriculado, técnico electrónico. Las invitaciones serán enviadas por correo electrónico a la dirección provista y/o vía whatsapp al número proporcionado en el formulario de inscripción. La veracidad de los datos provistos resulta fundamental para el cumplimiento de esta propuesta de capacitaciones gratuitas.</i></p>
                    <p><i>Finalizada la capacitación, quienes hayan participado fehacientemente recibirán un mail con un link de acceso a toda la información brindada en dicha capacitación.</i></p>

                    <p><i>Se entregará Certificado de Participación que corrobora la participación en la capacitación. </i></p>

                </li>
            </ul>




            <ul>
                <li><p><b>Envío de novedades y pre lanzamiento de productos antes que al público final:</b> <i>estas novedades y avisos de lanzamientos de productos nuevos se enviarán por correo electrónico a la dirección provista y/o vía whatsapp al número proporcionado en el formulario de inscripción. La veracidad de los datos provistos resulta fundamental para el cumplimiento de esta propuesta de comunicación de novedades.</i></p>

                </li>
            </ul>



            <ul>
                <li><p><b>Presentaciones y capacitaciones técnicas de estos nuevos productos/líneas</b>: <i>las invitaciones serán enviadas a la dirección de correo electrónico provista y/o vía whatsapp al número proporcionado en el formulario de inscripción. La veracidad de los datos provistos resulta fundamental para el cumplimiento de esta propuesta de presentaciones y capacitaciones técnicas.</i></p>

                </li>
            </ul>



            <ul>
                <li><p><b>Aparecer en el mapa interactivo como punto de venta:</b> <i>La Empresa se compromete a dar de alta al ALMACÉN en el mapa de puntos de venta de su sitio web y ocasionalmente en historias en sus redes sociales que incluyan dirección y datos de contacto provistos por el ALMACÉN una vez que dicho ALMACÉN haya adquirido 20 teléfonos, en una o varias compras.</i></p>

                    <p><i>Requisitos: mantener stock disponible en todo momento. </i></p>

                    <p><i>La Empresa tiene derecho a eliminar a toda ALMACÉN ingresada en su mapa interactivo como punto de venta sin previo aviso en caso de violarse los términos y condiciones aquí descriptos o cualquier ley o reglamentación aplicable.</i></p>


                </li>
            </ul>


            <ul>
                <li><p><b>Acceso a un video explicativo de las funciones y prestaciones del VEO F2</b>: </p>
                    <p><i>el link de acceso será enviado a la dirección de correo electrónico provista y/o vía whatsapp al número proporcionado en el formulario de inscripción. La veracidad de los datos provistos resulta fundamental para el cumplimiento de esta propuesta de presentaciones y capacitaciones técnicas.</i></p>

                </li>
            </ul>


            <p><br />
            </p>

        </div>
    )
}

export default Terms