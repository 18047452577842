import React, { useContext, useState } from 'react';
import PreLoader from '../../../UI/PreLoader/PreLoader';
import Form from '../../../UI/Form/Form';
import BusinessInner from '../BusinessInner/BusinessInner';
import UIContext from '../../../../Context/UIContext';
import './BusinessAlmacenes.scss';


const BusinessAlmacenes = () => {
    // const { slug: indexSlug } = match.params;

    // ========================================================================
    // Form
    // ========================================================================
    const [data, setData] = useState(null);
    const UICx = useContext(UIContext);

    React.useEffect(() => {
        setData(true);
    });

    // ========================================================================
    // Hero
    // ========================================================================
    const hero = {
        section: "GREMIO",
        title: "UNITE A NUESTRA RED",
        subtitle: "Accede a beneficios, novedades, y capacitaciones sin costo."
    }

    // ========================================================================
    // Info
    // ========================================================================
    const info = {
        section: hero.section,
        body: null
    }

    // ========================================================================
    // Beneficios
    // ========================================================================
    const beneficios = {
        title: <>SUMATE A LA RED DE REVENDEDORES <br />DEL TELÉFONO UNIVERSAL VEO F2 <br />DE <span className='text-primary'>LABADIE BY FERMAX</span></>,
        accordion: [
            {
                title: "Equipo en comodato grátis",
                body: <p>Si sos electricista o instalador del rubro te ofrecemos un teléfono universal VEO F2 en comodato para que lo conozcas, ofrezcas a tus clientes y amplíes tu oferta.</p>
            },
            {
                title: "Descuento Adicional por única vez",
                body: <p>Si ya sabés que el teléfono universal VEO F2 puede sustituir a los teléfonos estándar de otras marcas por reemplazo directo y buscás tenerlo disponible para que tus clientes siempre dispongan de una solución, te ofrecemos un descuento adicional en tu primera compra.</p>
            },
            {
                title: "Capacitaciones gratuitas",
                body: <p>Si ya estás convencido de las bondades del teléfono universal VEO F2, con la confirmación de tu primera compra recibirás un link de acceso a capacitaciones gratuitas y demostraciones de toda nuestra línea de productos en nuestro showroom de Colegiales</p>
            },
            {
                title: "Tu nombre en nuestro mapa interactivo",
                body: <p>Convertite en revendedor e instalador del teléfono universal VEO F2 y tu nombre aparecerá como técnico SUGERIDO en el mapa interactivo de nuestro sitio web y en nuestras redes sociales. ¿Todavía no te convenciste?</p>
            },
        ]
    }

    // ========================================================================
    // Formulario
    // ========================================================================
    const formFields = {
        name: {
            "label": "Nombre",
            "type": "text",
            "required": true,
        },
        surname: {
            "label": "Apellido",
            "type": "text",
            "required": true,
        },
        phone: {
            "label": "Número de teléfono",
            "type": "number",
            "required": true,
        },
        email: {
            "label": "Dirección de e-Mail",
            "type": "email",
            "required": true,
        },
        company: {
            "label": "Empresa",
            "type": "text",
            "required": true,
        },
        cuit: {
            "label": "CUIT de la empresa",
            "type": "number",
            "required": true
        },
        localidad: {
            "label": "Localidad",
            "type": "text",
            "required": false,
        },
        address: {
            "label": "Dirección",
            "type": "text",
            "required": false,
        },
        terms: {
            "label": <span onClick={() => UICx.setShowModal('terms-gremio')}>Accepto los <strong>términos y condiciones</strong></span>,
            "type": "checkbox",
            "required": true,
        },
    }

    const send = <>Enviar formulario <span className="icon icon-cheveron-right icon-secondary"></span></>;
    const sended = <>
        <div className="sended alert alert-success">¡Formulario completado con éxito! Te contactaremos a la brevedad. ¡No olvides verificar tu email! <span className="icon-checkmark icon text-success"></span></div>
    </>;
    const error = <>
        Por favor revise los campos marcados con <strong>*</strong>, y tilde la casilla de verificación.
    </>;

    const footer = <p>Los campos marcados con * son obligatorios</p>;

    // Form State
    const formRender = (data) ?
        <Form formId={'gremio'} formBusiness data={formFields} sended={sended} error={error} send={send} footer={footer} targetUrl={'negocios-consorcios'} /> :
        <PreLoader />;

    // ========================================================================
    // Component body
    // ========================================================================
    return (
        <div className="BusinessAlmacenes Contact">
            <BusinessInner hero={hero} info={info} beneficios={beneficios} slug={'consorcios'}>
                {formRender}
            </BusinessInner>
        </div>
    )
}

export default BusinessAlmacenes