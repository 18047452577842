import React from 'react';
import './TextIcon.scss';

const TextIcon = (props) => {
    return (
        <div
            className={"TextIcon " + props.mode}
            onClick={props.clicked}>
            <span className={"icon icon-" + props.icon}></span>
            {props.children}
        </div>
    )
}

export default TextIcon