import React, { useState, useContext, useEffect } from 'react';
import './Contact.scss';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Form from '../../UI/Form/Form';
import Sidebar from './Sidebar';


const Presupuesto = ({ match, ...props }) => {
    //const { slug: indexSlug } = match.params;
    const tagMailSender = props.location.state?.tagMailSender;
    const [data, setData] = useState(null);

    React.useEffect(() => {
        setData(true);
    });

    const formFields = {
        name: {
            "label": "Nombre",
            "type": "text",
            "required": true,
        },
        email: {
            "label": "Dirección de e-Mail",
            "type": "email",
            "required": true,
        },
        phone: {
            "label": "Número de teléfono",
            "type": "text",
            "required": false,
        },
        address: {
            "label": "Dirección de la obra",
            "type": "text",
            "required": true,
        },
        instalation: {
            "label": "Tipo de instalación ",
            "type": "text",
            "required": true,
            "hint": "Ej: Videoportero®, CCTV, Control de acceso, Sistema combinado, etc."
        },
        living: {
            "label": "Tipo de vivienda",
            "type": "text",
            "required": true,
            "hint": "Ej: Vivienda unifamiliar, Edificio 25 dptos, Industria, etc."
        },
        comment: {
            "label": "Comentarios adicionales",
            "type": "textarea",
            "required": false,
            "hint": "Coméntenos si se trata de una instalación nueva, una reinstalación, reposición de equipos, o cualquier información que considere pertinente."
        },
    }

    const send = <>Enviar formulario <span className="icon icon-cheveron-right icon-secondary"></span></>;
    const sended = <>
        <div className="sended alert alert-success">Mensaje enviado, le responderemos a la brevedad. Muchas gracias. <span className="icon-checkmark icon text-success"></span></div>
    </>;
    const error = <>
        Por favor revise los campos marcados con <strong>*</strong>, y tilde la casilla de verificación.
        <span className="icon-close icon-secondary text-white"></span>
    </>;

    const footer = <p>Los campos marcados con * son obligatorios</p>;

    // Form State
    const sectionRender = (data) ?
        <Form formId={'presupuesto'} data={formFields} sended={sended} error={error} send={send} footer={footer} targetUrl={(tagMailSender?tagMailSender:'quote')} /> :
        <PreLoader />;

    return (
        <div className="Contact">
            <WithTitle section='CONTACTO' title={'Solicitud de presupuesto'} type="main-block--padding">
                <div className="contact__inner">
                    <div className="contact__main">
                        {sectionRender}
                    </div>
                    <div className="contact__sidebar">
                        <Sidebar />
                    </div>
                </div>
            </WithTitle>
        </div>
    )
}

export default Presupuesto