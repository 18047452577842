import React from 'react';
import './Footer.scss';

import TextIcon from '../../UI/TextIcon/TextIcon';
import BtnIconBox from '../../UI/BtnIconBox/BtnIconBox';
import SocialNav from '../../UI/SocialNav/SocialNav';
import IconColumn from '../../UI/IconColumn/IconColumn';
import UIContext from '../../../Context/UIContext';
import { useHistory } from 'react-router-dom';

const Footer = () => {

    // TODO Copyright year from php
    const copyright = '© 2024 Labadie Videosistemas';
    const UICx = React.useContext(UIContext);
    const history = useHistory();

    const gotToQuote = () => {
        UICx.setFormSubject('');
        history.push({ pathname: "/solicitar-presupuesto", state: {tagMailSender: 'zocalo-solicitar-presupuesto'}});
    }
    const estilos = {
        href_wsp: {
            position: "fixed",
            zIndex: "125",
            bottom: "2rem",
            right: "2.5rem",
            color: "white",
            width: "2.5em",
            height: "2.5em",
        },
        span_wsp: {
            fontSize: "1.2em"
        }
      }

    return (

        <div className="Footer">
            <a href="https://api.whatsapp.com/send?phone=5491151814378&text=Hola,%20me%20comunico%20con%20ustedes%20desde%20su%20página%20web.Quisiera%20consultar%20sobre:" className="btn-circle btn-info" style={estilos.href_wsp} target="_blank">
                <span className="icon icon-whatsapp" style={estilos.span_wsp}></span>
            </a>
            <div className="inner">
                <div className="Col text-center copyright">
                    <p>{copyright}</p>
                </div>
                <div className="Col col-h">
                    <div>
                        <IconColumn icon="map">
                            <p className="title">UBICACIÓN</p>
                            <p>Zapata 277 (C1426AEC)<br />Buenos Aires, C.A.B.A</p>
                        </IconColumn>
                        <IconColumn icon="clock">
                            <p className="title">HORARIOS DE ATENCIÓN</p>
                            <p>De lunes a viernes<br />
                                <strong>8:30 a 16.00hs</strong></p>
                        </IconColumn>
                    </div>
                    <div className="show-mobile">
                        <button onClick={() => UICx.setShowModal('map')} className="btn-circle btn-info">
                            <span className="icon icon-map"></span>
                        </button>
                    </div>
                    <div className="hide-mobile">
                        <BtnIconBox clicked={() => UICx.setShowModal('map')} icon="map">VER UBICACIÓN EN MAPA</BtnIconBox>
                    </div>
                </div>
                <div className="Col col-h">
                    <div>
                        <IconColumn icon="phone">
                            <p className="title">TELÉFONO</p>
                            <p className="phone">4775-1122</p>
                        </IconColumn>
                        <IconColumn icon="mail">
                            <p className="title">E-MAIL</p>
                            <a href="mailto:hola@labadie.com.ar">hola@labadie.com.ar</a>
                        </IconColumn>
                    </div>
                    <div className="show-mobile">
                        <button onClick={() =>history.push({ pathname: "/contacto", state: {tagMailSender: 'zocalo-consultas-generales'}})} className="btn-circle btn-info">
                            <span className="icon icon-bubble"></span>
                        </button>
                    </div>
                    <div className="hide-mobile">
                        <BtnIconBox clicked={() => history.push({ pathname: "/contacto", state: {tagMailSender: 'zocalo-consultas-generales'}})} icon="bubble">Formulario de contacto</BtnIconBox>
                    </div>
                </div>
                <div className="Col">
                    <div>
                        <div>
                            <BtnIconBox clicked={() => {history.push( { pathname: "/servicio-tecnico", state: {tagMailSender: 'zocalo-servicio-tecnico'}});}} icon="case" variant="light">SOLICITAR SERVICIO TÉCNICO</BtnIconBox>
                        </div>
                        <div className="my-3">
                            <BtnIconBox clicked={gotToQuote} icon="money">Solicitar Presupuesto</BtnIconBox>
                        </div>
                    </div>
                    <div className="social-wrapper hide-mobile">
                        <p>SEGUINOS EN LAS REDES</p>
                        <div className="social__icons">
                            <SocialNav />
                            {/* <div>
                                <span className="icon icon-instagram"></span>
                                <span className="icon icon-linkedin"></span>
                            </div> */}
                            <p>{copyright}</p>
                        </div>
                    </div>
                    <div className="show-mobile my-3">
                        <div className="d-flex justify-content-between align-content-center">
                            <p className="mb-0 mt-1">SEGUINOS EN LAS REDES</p>
                            <SocialNav />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer