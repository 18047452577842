import React, { useState, useContext, useEffect } from 'react';
import './Contact.scss';
// import CatalogContext from '../../../Context/CatalogContext';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Form from '../../UI/Form/Form';
import Sidebar from './Sidebar';

const ServicioTecnico = ({ match, ...props }) => {
    // const { slug: indexSlug } = match.params;
    // const cCx = useContext(CatalogContext);
    const [data, setData] = useState(null);
    const tagMailSender = props.location.state?.tagMailSender;
    React.useEffect(() => {
        setData(true);
    });

    // TODO build jsons for different forms
    const formFields = {
        name: {
            "label": "Nombre del cliente",
            "type": "text",
            "required": true,
            "hint": "(Ej: Cons. Prop. Av. Del Libertador 1594)"
        },
        requestedBy: {
            "label": "Solicitado por",
            "type": "text",
            "required": true,
        },
        cuit: {
            "label": "CUIT",
            "type": "text",
            "required": true,
        },
        email: {
            "label": "Dirección de E-Mail",
            "type": "email",
            "required": true,
        },
        phone: {
            "label": "Nº de teléfono",
            "type": "text",
            "required": true,
        },
        address: {
            "label": "Dirección",
            "type": "text",
            "required": true,
        },
        comment: {
            "label": "Desperfecto",
            "type": "textarea",
            "required": false,
        },
    }

    const send = <>Enviar formulario <span className="icon icon-cheveron-right icon-secondary"></span></>;
    const sended = <>
        <div className="sended alert alert-success">Mensaje enviado, le responderemos a la brevedad. Muchas gracias. <span className="icon-checkmark icon text-success"></span></div>
    </>;
    const error = <>
        Por favor revise los campos marcados con <strong>*</strong>, y tilde la casilla de verificación.
        <span className="icon-close icon-secondary text-white"></span>
    </>;
    const footer = <>
        <p>Las visitas de servicio técnico se realizan de lunes a viernes en el horario de 8.30 a 17.30, previa confirmación para coordinar la misma.</p>
    </>;

    // Form State
    const sectionRender = (data) ?
        <Form data={formFields} sended={sended} error={error} send={send} footer={footer} targetUrl={tagMailSender?tagMailSender:'technical'} /> :
        <PreLoader />;

    return (
        <div className="Contact">
            <WithTitle section='CONTACTO' title={'Solicitud de Servicio Técnico'} type="main-block--padding">
                <div className="contact__inner">
                    <div className="contact__main">
                        {sectionRender}
                    </div>
                    <div className="contact__sidebar">
                        <Sidebar />
                    </div>
                </div>
            </WithTitle>
        </div>
    )
}

export default ServicioTecnico