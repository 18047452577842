import React from 'react';
import './BtnIconBox.scss';

const BtnIconBox = (props) => {
    return (
        <div onClick={props.clicked} className={"BtnIconBox " + props.variant}>
            {props.children}
            <span className={"pl-4 icon icon-" + props.icon}></span>
        </div>
    )
}

export default BtnIconBox