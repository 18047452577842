import React from 'react';
import utils from '../../../data/utils.json';
import './TitleMedium.scss';

const TitleMedium = ({ children, section }) => {
    return (
        <div className="TitleMedium">
            {/* <h5 className="section">{section}</h5> */}
            <h3 className="title">{children}</h3>
        </div>
    )
}

export default TitleMedium