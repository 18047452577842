import React, { useState, useContext, useEffect } from 'react';
import './Projects.scss';
import SectionInfo from '../../HOC/SectionInfo/SectionInfo';
import { withRouter, useHistory } from 'react-router-dom';
import ProjectItem from './ProjectItem';
import UIContext from '../../../Context/UIContext';
import axios from 'axios';
import utils from '../../../data/utils.json';
import ReactHtmlParser from 'react-html-parser';
import PreLoader from '../../UI/PreLoader/PreLoader';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Pagination from 'react-js-pagination';

const Projects = ({ match, ...props }) => {
    const UICx = useContext(UIContext);
    const [data, setData] = useState(null);
    const { page: currentPage } = match.params;
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        axios.get(utils.server + 'get/projects/' + currentPage)
            .then(function (response) {
                setData(response.data);
                setTotalItems(response.data.length);
                setLoading(false);
            })
    }, []);

    const projects = (data) ?
        <div className="project-list">
            <div className='row'>
            {
                data[1].map(project => <ProjectItem key={project.id} data={project} />)
            }
            </div>
        </div> : <div className="no-results">
            <p>No se encontraron proyectos en esta página.</p>
        </div>;

    const renderBody = (!loading) ?
        <>
            <WithTitle
                section='EMPRESA'
                title={'PROYECTOS DESTACADOS'} />
            {/* <SectionInfo data={data[0]} section="INSTITUCIONAL">
                <div className="header">
                    {(data.subTitle) &&
                        <h3>{data[0].subTitle}</h3>}
                    {(data.shortDescription) &&
                        <p>{data[0].shortDescription}</p>}
                </div>
                <div className="body">
                    {ReactHtmlParser(data[0].body)}
                </div>
            </SectionInfo> */}
            {/* <h2 className="PageTitle mt-2">OBRAS REALIZADAS</h2> */}
            <div className="projects-wrapper main-block--padding">
                {projects}
            </div>
        </> : <PreLoader />;

    // const renderBody = (loading) ? <PreLoader /> : renderBody;

    return (
        <div className="Projects">
            {renderBody}
            {totalItems > UICx.itemsPerPage && <Pagination
                activePage={parseInt(currentPage)}
                itemsCountPerPage={UICx.itemsPerPage}
                totalItemsCount={totalItems}
                pageRangeDisplayed={5}
                hideNavigation={true}
                hideFirstLastPages={true}
                innerClass={"Pagination"}
                onChange={(n) => history.push('/proyectos/' + n)}
            />}
        </div>
    )
}

export default withRouter(Projects);