import React from 'react';
import WithTitle from '../../HOC/WithTitle/WithTitle';
import Button from '../../UI/Button/Button';
import './HeroContainer.scss';

import img01 from '../../../assets/images/almacenes-01.png';

const HeroContainer = ({ action, section, title, subtitle, slug }) => {
    return (
        <div className="HeroContainer">
            <div className="info container-center">
                <div className="container-title">
                    <h5 className="section">{section}</h5>
                    <h1 className="title">{title}</h1>
                    <p className='description'>{subtitle}</p>
                </div>
                <div className="actions container">
                    <nav>
                        <Button modifiers='second ml-md-4' action={() => action("feature-list")}>BENEFICIOS</Button>
                        <Button modifiers={'first ' + slug + ' ml-3 ml-md-4'} action={() => action("action-form")}>UNITE GRÁTIS AHORA</Button>
                    </nav>
                </div>
            </div>
            <div className="image">
                <img src={img01} alt="" />
            </div>
            <div className="overlay"></div>
        </div>
    )
}

export default HeroContainer