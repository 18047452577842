import React, { useContext } from 'react';
import './Sidedrawer.scss';
import Footer from '../../Layout/Footer/Footer';
import BtnTextIcon from '../../UI/BtnTextIcon/BtnTextIcon';
import BtnCollapse from '../../UI/BtnCollapse/BtnCollapse';
import UIContext from '../../../Context/UIContext';
import MainNav from '../MainNav/MainNav';
import NavData from '../MainNav/NavData';
import { useSpring, animated } from 'react-spring';
import SocialNav from '../../UI/SocialNav/SocialNav';

const Sidedrawer = () => {
    const UICx = useContext(UIContext);
    const drawerSpring = useSpring({
        right: UICx.showDrawer ? "0%" : "-100%"
    });

    return (
        <animated.div style={drawerSpring} className="SideDrawer">
            <div className="top">
                <MainNav mobile={true} />
            </div>
            <div className="bottom">
                <NavData />
                <SocialNav />
            </div>
        </animated.div>
    );
};

export default Sidedrawer;
