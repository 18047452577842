import React, { useState, useEffect, useRef } from 'react';
import './ProductCategories.scss';
import Slider from "react-slick";
import { useWindowDimensions } from '../../Utils/WindowDimensionsProvider';
import CatalogContext from '../../../Context/CatalogContext';
import SliderArrows from '../../UI/SliderArrows/SliderArrows';
import CategoryItem from './CategoryItem/CategoryItem';
import { useHistory } from 'react-router-dom';

const ProductCategories = (props) => {

    const cCx = React.useContext(CatalogContext);
    const vpSize = useWindowDimensions();
    const [slides, setSlides] = useState(3);
    const [categories, setCategories] = useState(null);
    const currentCategory = props.current;

    const slider = useRef(null);

    useEffect(() => {
        setCategories(cCx.categories);
    }, [cCx.categories])

    useEffect(() => {

        switch (vpSize.getBreakpoint) {
            case 'md':
            case 'lg':
            case 'xl':
                setSlides(4);
                break;
            default:
                setSlides(3);
        }

    }, [vpSize.getBreakpoint])

    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: slides,
        slidesToScroll: slides
    };

    const renderCategories = categories ?
        categories.map(item => {
            return <CategoryItem key={'category-' + item.id} data={item} active={item.slug === currentCategory} />
        }) : null;

    return (
        <div className="ProductCategories full slider-wrapper">
            <SliderArrows slider={slider} items={categories} itemsPerPage={slides}>
                <Slider ref={slider} {...settings}>
                    <CategoryItem data={null} />
                    {renderCategories}
                </Slider>
            </SliderArrows>
        </div>
    )
}

export default ProductCategories