import React from 'react';
import './Layout.scss';
import TopNav from './TopNav/TopNav';
// import Footer from './Footer/Footer';
import Sidedrawer from '../Layout/Sidedrawer/Sidedrawer';
import UIContext from '../../Context/UIContext';
import { useSpring, animated } from 'react-spring';

const Layout = (props) => {
    const UICx = React.useContext(UIContext);

    const handleClick = () => {
        if (UICx.showDrawer) UICx.setShowDrawer(false);
    }

    return (
        <div className="Layout">
            <div>
                <TopNav />
                <section className="main-content" onClick={handleClick}>
                    {props.children}
                </section>
            </div>
            {/* <Footer /> */}
            <Sidedrawer />
        </div>
    )
}

export default Layout